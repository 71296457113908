import React, { useState } from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import LeaveRequest from '../../../../hooks/leaveRequest/LeaveRequest'
import TickContainer from '../../../../hooks/tickContainer/TickContainer'

const data = [
    {
        text: <h5><span className='bold'>РАЗРАБОТЧИКАМ ИПРОГРАММИСТАМ,</span><br />
            чтобы прокачаться в новом для себя языке</h5>
    },
    {
        text: <h5><span className='bold'>МОБИЛЬНЫМ ТЕСТИРОВЩИКАМ,</span><br />
            которые уже готовы на большее</h5>
    },
    {
        text: <h5><span className='bold'>ОСНОВАТЕЛЯМ СТРАТАПОВ,</span><br />
            чтобы уметь быстро ставить задачи программистам, сокращать и оптимизировать затраты</h5>
    },
    {
        text: <h5><span className='bold'>НОВИЧКАМ,</span><br />
            которые не программировали, но хотели бы научиться</h5>
    },
    {
        text: <h5><span className='bold'>ПРОДАКТ-МЕНЕДЖЕРАМ,</span><br />
            чтобы быстро создавать прототипы новых функций и продуктов</h5>
    },
    {
        text: <h5><span className='bold'>ДИЗАЙНЕРАМ,</span><br />
            которые хотят претворять концепты в прототипы</h5>
    },
    {
        text: <h5><span className='bold'>HR-МЕНЕДЖЕРАМ,</span><br />
            Ищущие и собеседующие iOS-программистов</h5>
    },
    {
        text: <h5><span className='bold'>ЭНТУЗИАСТАМ,</span><br />
            экспериментирующие на стыке разных областей</h5>
    }
]

function ForWho() {
    const [open, setOpen] = useState("")

    return (
        <>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                <div className='for-who'>
                    <TickContainer h1={'Кому полезно учиться программированию на iOS'} data={data} buttonClass={'for-who__button'} buttonText={'Оставить заявку'} setOpen={setOpen} />
                </div>
            </AnimationOnScroll>
            {open &&
                <LeaveRequest open={open} setOpen={setOpen} />
            }
        </>
    )
}

export default ForWho
import React from 'react'
import { Link } from 'react-router-dom'

function Navigation({ items, closeNav }) {
    return (
        <>
            {items.submenu ? (
                <>
                    {items.submenu.map((instance, index) => (
                        <li className="nav-item" key={index}>
                            <Link to={instance.url} className="link" onClick={closeNav}>{instance.title}</Link>
                        </li>
                    ))}
                </>
            ) : (
                <li className="nav-item">
                    <Link to={items.url} className="link" onClick={closeNav}>{items.title}</Link>
                </li>
            )}
        </>
    )
}

export default Navigation
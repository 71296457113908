import React, { useState, useEffect } from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import Media from "react-media"

import lines from '../../../../components/images/svgs/about-us/all-steps/lines.svg'

function AllSteps() {
    const [active, setActive] = useState(1)
    const [height1, setHeight1] = useState(0)
    const [height2, setHeight2] = useState(0)

    useEffect(() => {
        let timer1 = setTimeout(() => {
            let line1 = document.getElementById("line1");
            let line2 = document.getElementById("line2");
            let line3 = document.getElementById("line3");
            let line4 = document.getElementById("line4");
            let line5 = document.getElementById("line5");
            let line6 = document.getElementById("line6");
            let line7 = document.getElementById("line7");
            let line8 = document.getElementById("line8");

            let div1 = document.getElementById("one");
            let div2 = document.getElementById("two");
            let div3 = document.getElementById("three");
            let div4 = document.getElementById("four");
            let div5 = document.getElementById("five");
            let div6 = document.getElementById("six");
            let div7 = document.getElementById("seven");
            let div8 = document.getElementById("eight");
            let div9 = document.getElementById("nine");
            let div10 = document.getElementById("ten");

            let x1s = div1.offsetLeft + (div1.offsetWidth) + 7;
            let y1s = div1.offsetTop + (div1.offsetHeight / 2);
            let x2e = div2.offsetLeft - 7;
            let y2e = div2.offsetTop + (div2.offsetHeight / 2);

            let x2s = div2.offsetLeft + (div2.offsetWidth) + 7;
            let y2s = div2.offsetTop + (div2.offsetHeight / 2);
            let x3e = div3.offsetLeft - 7;
            let y3e = div3.offsetTop + (div3.offsetHeight / 2);

            let x3s = div3.offsetLeft + (div3.offsetWidth) + 7;
            let y3s = div3.offsetTop + (div3.offsetHeight / 2);
            let x4e = div4.offsetLeft - 7;
            let y4e = div4.offsetTop + (div4.offsetHeight / 2);

            let x4s = div4.offsetLeft + (div4.offsetWidth) + 7;
            let y4s = div4.offsetTop + (div4.offsetHeight / 2);
            let x5e = div5.offsetLeft - 7;
            let y5e = div5.offsetTop + (div5.offsetHeight / 2);


            let x6s = div6.offsetLeft + (div6.offsetWidth) + 7;
            let y6s = div6.offsetTop + (div6.offsetHeight / 2);
            let x7e = div7.offsetLeft - 7;
            let y7e = div7.offsetTop + (div7.offsetHeight / 2);

            let x7s = div7.offsetLeft + (div7.offsetWidth) + 7;
            let y7s = div7.offsetTop + (div7.offsetHeight / 2);
            let x8e = div8.offsetLeft - 7;
            let y8e = div8.offsetTop + (div8.offsetHeight / 2);

            let x8s = div8.offsetLeft + (div8.offsetWidth) + 7;
            let y8s = div8.offsetTop + (div8.offsetHeight / 2);
            let x9e = div9.offsetLeft - 7;
            let y9e = div9.offsetTop + (div9.offsetHeight / 2);

            let x9s = div9.offsetLeft + (div9.offsetWidth) + 7;
            let y9s = div9.offsetTop + (div9.offsetHeight / 2);
            let x10e = div10.offsetLeft - 7;
            let y10e = div10.offsetTop + (div10.offsetHeight / 2);

            var height1 = div1.offsetTop + div1.offsetHeight;
            var height2 = div6.offsetTop + div6.offsetHeight;

            setHeight1(height1)
            setHeight2(height2)

            line1.setAttribute('x1', x1s)
            line1.setAttribute('y1', y1s)
            line1.setAttribute('x2', x2e)
            line1.setAttribute('y2', y2e)

            line2.setAttribute('x1', x2s)
            line2.setAttribute('y1', y2s)
            line2.setAttribute('x2', x3e)
            line2.setAttribute('y2', y3e)

            line3.setAttribute('x1', x3s)
            line3.setAttribute('y1', y3s)
            line3.setAttribute('x2', x4e)
            line3.setAttribute('y2', y4e)

            line4.setAttribute('x1', x4s)
            line4.setAttribute('y1', y4s)
            line4.setAttribute('x2', x5e)
            line4.setAttribute('y2', y5e)


            line5.setAttribute('x1', x6s)
            line5.setAttribute('y1', y6s)
            line5.setAttribute('x2', x7e)
            line5.setAttribute('y2', y7e)

            line6.setAttribute('x1', x7s)
            line6.setAttribute('y1', y7s)
            line6.setAttribute('x2', x8e)
            line6.setAttribute('y2', y8e)

            line7.setAttribute('x1', x8s)
            line7.setAttribute('y1', y8s)
            line7.setAttribute('x2', x9e)
            line7.setAttribute('y2', y9e)

            line8.setAttribute('x1', x9s)
            line8.setAttribute('y1', y9s)
            line8.setAttribute('x2', x10e)
            line8.setAttribute('y2', y10e)
        }, 1000);

        return () => {
            clearTimeout(timer1);
        }
    }, [])

    useEffect(() => {
        let line1 = document.getElementById("line1");
        let line2 = document.getElementById("line2");
        let line3 = document.getElementById("line3");
        let line4 = document.getElementById("line4");
        let line5 = document.getElementById("line5");
        let line6 = document.getElementById("line6");
        let line7 = document.getElementById("line7");
        let line8 = document.getElementById("line8");

        let div1 = document.getElementById("one");
        let div2 = document.getElementById("two");
        let div3 = document.getElementById("three");
        let div4 = document.getElementById("four");
        let div5 = document.getElementById("five");
        let div6 = document.getElementById("six");
        let div7 = document.getElementById("seven");
        let div8 = document.getElementById("eight");
        let div9 = document.getElementById("nine");
        let div10 = document.getElementById("ten");
        const handleResize = () => {

            let x1s = div1.offsetLeft + (div1.offsetWidth) + 7;
            let y1s = div1.offsetTop + (div1.offsetHeight / 2);
            let x2e = div2.offsetLeft - 7;
            let y2e = div2.offsetTop + (div2.offsetHeight / 2);

            let x2s = div2.offsetLeft + (div2.offsetWidth) + 7;
            let y2s = div2.offsetTop + (div2.offsetHeight / 2);
            let x3e = div3.offsetLeft - 7;
            let y3e = div3.offsetTop + (div3.offsetHeight / 2);

            let x3s = div3.offsetLeft + (div3.offsetWidth) + 7;
            let y3s = div3.offsetTop + (div3.offsetHeight / 2);
            let x4e = div4.offsetLeft - 7;
            let y4e = div4.offsetTop + (div4.offsetHeight / 2);

            let x4s = div4.offsetLeft + (div4.offsetWidth) + 7;
            let y4s = div4.offsetTop + (div4.offsetHeight / 2);
            let x5e = div5.offsetLeft - 7;
            let y5e = div5.offsetTop + (div5.offsetHeight / 2);


            let x6s = div6.offsetLeft + (div6.offsetWidth) + 7;
            let y6s = div6.offsetTop + (div6.offsetHeight / 2);
            let x7e = div7.offsetLeft - 7;
            let y7e = div7.offsetTop + (div7.offsetHeight / 2);

            let x7s = div7.offsetLeft + (div7.offsetWidth) + 7;
            let y7s = div7.offsetTop + (div7.offsetHeight / 2);
            let x8e = div8.offsetLeft - 7;
            let y8e = div8.offsetTop + (div8.offsetHeight / 2);

            let x8s = div8.offsetLeft + (div8.offsetWidth) + 7;
            let y8s = div8.offsetTop + (div8.offsetHeight / 2);
            let x9e = div9.offsetLeft - 7;
            let y9e = div9.offsetTop + (div9.offsetHeight / 2);

            let x9s = div9.offsetLeft + (div9.offsetWidth) + 7;
            let y9s = div9.offsetTop + (div9.offsetHeight / 2);
            let x10e = div10.offsetLeft - 7;
            let y10e = div10.offsetTop + (div10.offsetHeight / 2);

            var height1 = div1.offsetTop + div1.offsetHeight;
            var height2 = div6.offsetTop + div6.offsetHeight;

            setHeight1(height1)
            setHeight2(height2)

            line1.setAttribute('x1', x1s)
            line1.setAttribute('y1', y1s)
            line1.setAttribute('x2', x2e)
            line1.setAttribute('y2', y2e)

            line2.setAttribute('x1', x2s)
            line2.setAttribute('y1', y2s)
            line2.setAttribute('x2', x3e)
            line2.setAttribute('y2', y3e)

            line3.setAttribute('x1', x3s)
            line3.setAttribute('y1', y3s)
            line3.setAttribute('x2', x4e)
            line3.setAttribute('y2', y4e)

            line4.setAttribute('x1', x4s)
            line4.setAttribute('y1', y4s)
            line4.setAttribute('x2', x5e)
            line4.setAttribute('y2', y5e)


            line5.setAttribute('x1', x6s)
            line5.setAttribute('y1', y6s)
            line5.setAttribute('x2', x7e)
            line5.setAttribute('y2', y7e)

            line6.setAttribute('x1', x7s)
            line6.setAttribute('y1', y7s)
            line6.setAttribute('x2', x8e)
            line6.setAttribute('y2', y8e)

            line7.setAttribute('x1', x8s)
            line7.setAttribute('y1', y8s)
            line7.setAttribute('x2', x9e)
            line7.setAttribute('y2', y9e)

            line8.setAttribute('x1', x9s)
            line8.setAttribute('y1', y9s)
            line8.setAttribute('x2', x10e)
            line8.setAttribute('y2', y10e)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)

        }
    }, [])

    const data = [{
        text: <>
            <p>
                В первую очередь необходимо определить, какие потребности пользователей и клиента должно решать приложение, а также сформулировать его основные задачи. Этому этапу уделяется особое внимание: от задания зависят технические особенности будущего продукта. Упустив даже незначительную на первый взгляд деталь и не заложив ее в архитектуру приложения, мы можем столкнуться с необходимостью переделывать его практически с нуля.<br /><br />
                На этом этапе мы:
            </p>
            <ul>
                <li><p>составляем подробное описание функционала приложения;</p></li>
                <li><p>определяем временные рамки и финансовые затраты на работу;</p></li>
                <li><p>оформляем договор с клиентом.</p></li>
            </ul>
        </>
    },
    {
        text: <>
            <p>
                Чтобы понять, как покупатель будет пользоваться приложением, мы создаем графическую карту взаимодействия между экранами, также на данном этапе прорабатывается практически весь функционал продукта. Проектирование UI/UX является созданием прототипа приложения: мы реализуем все описанные в техническом задании функции, определяем, как будет работать приложение и как будет работать с ним пользователь, продумываем, какие кнопки и какой функционал будет размещен на каждом экране.<br /><br />
                На этом этапе мы:
            </p>
            <ul>
                <li><p>отрабатываем функционал приложения и окончательно продумываем сценарий поведения пользователя;</p></li>
                <li><p>разрабатываем схемы всех экранов с указанием функционала для каждого из них.</p></li>
                <li><p>на схеме показываем связь всех экранов, то есть продумываем, как пользователь будет переходить на них.</p></li>
            </ul>
        </>
    }, {
        text: <>
            <p>На примере основных экранов приложения (1–3) мы показываем его будущий дизайн, отталкиваясь в первую очередь от целей, аудитории и функционала.<br /><br />
                На этом этапе мы:
            </p>
            <ul>
                <li><p>детально прорабатываем от 1 до 3 экранов будущего приложения;</p></li>
                <li><p>при необходимости создаем дизайн в нескольких разных стилях, чтобы выбрать наиболее подходящий.</p></li>
            </ul>
        </>
    },
    {
        text: <>
            <p>После утверждения концепции дизайна мы отрисовываем все остальные экраны, кнопки, иконки, экраны с помощью и подсказками и т. д. — то есть соединяем результат проектирования и создания концепции дизайна. Сроки работы зависят от сложности концепции и общего количества экранов приложения.<br /><br />
                На этом этапе мы:
            </p>
            <ul>
                <li><p>детально прорабатываем все экраны будущего приложения.</p></li>
            </ul>
        </>
    },
    {
        text: <>
            <p>На этом этапе мы верстаем все элементы приложения, т. е. из статичной картинки делаем интерактивную рабочую модель. Также мы соединяем серверную и клиентскую часть приложения по Rest Api, чтобы оно взаимодействовало с пользователем и полноценно работало.<br /><br />
                На этом этапе мы:
            </p>
            <ul>
                <li><p>получаем первую версию работающего приложения;</p></li>
                <li><p>отправляем клиенту файл, который он может установить на свои мобильные устройства.</p></li>
            </ul>
        </>
    },
    {
        text: <>
            <p>В течение нескольких дней мы тестируем приложение, после чего формируем отчет со всеми ошибками. При желании клиента мы отправляем ему тестовую версию приложения, чтобы он увидел, как оно работает.<br /><br />
                На этом этапе мы:
            </p>
            <ul>
                <li><p>составляем список всех недоработок, ошибок и недочетов в функционале приложения;</p></li>
                <li><p>определяем сроки на доработку приложения.</p></li>
            </ul>
        </>
    },
    {
        text: <>
            <p>Проектируя мобильное приложение, не всегда возможно предусмотреть все особенности его живого использования. Все приложения уникальны, и появление ошибок на первом этапе работы неизбежно. В большинстве случаев отладка занимает примерно половину времени от первоначальной разработки мобильных приложений.<br /><br />
                На этом этапе мы:
            </p>
            <ul>
                <li><p>получаем приложение с исправленными ошибками и, возможно, измененным функционалом.</p></li>
            </ul>
        </>
    },
    {
        text: <>
            <p>Перед запуском приложения в App Store и Google Play мы должны убедиться, что все недочеты предыдущей версии были исправлены и приложение правильно работает. Поэтому необходимо еще раз проверить всю логику приложения, работу его серверной части, протестировать его в разных условиях (например, при быстром последовательном нажатии кнопок «вперед» и «назад», одновременном нажатии нескольких кнопок, запуске на старой моделях сматрфонов с новой операционной системой или наоборот).<br /><br />
                На этом этапе мы:
            </p>
            <ul>
                <li><p>получаем полностью готовое работающее приложение.</p></li>
            </ul>
        </>
    },
    {
        text: <>
            <p>Иконка приложения является его неотъемлемой частью. Обычно это не просто уменьшенный логотип компании, а самостоятельный графический элемент. Как и при создании любой иллюстрации, при создании иконки сначала рисуется ее эскиз, затем он корректируется, прорисовывается и утверждается. Дизайнеру нужно отрисовать иконку в 6 разных размерах, убедиться, что она корректно отображается на всех экранах, хорошо смотрится в App Store и Google Play, заметна в общем списке и пользователям захочется на нее кликнуть и скачать или купить ваше приложение.<br /><br />
                На этом этапе мы:
            </p>
            <ul>
                <li><p>получаем комплект иконок для мобильного приложения.</p></li>
            </ul>
        </>
    },
    {
        text: <>
            <p>Перед запуском в магазины App Store и Google Play компании проверяют соответствие приложения своим стандартам и техническим особенностям. Специалисты Apple могут попросить внести в приложение некоторые изменения. Этот этап занимает от нескольких дней до двух недель. Мы успешно занимаемся разработкой мобильных приложенйи в Алматы.<br /><br />
                На этом этапе мы:
            </p>
            <ul>
                <li><p>выкладываем приложение в App Store и Google Play.</p></li>
            </ul>
        </>
    }
    ]

    return (
        <div className='all-steps'>
            <div className="upper-part">
                <div className="items">
                    <div className={active === 1 ? "item active" : "item"} onClick={() => setActive(1)}>
                        <div id="one" className="circle"><div className="inner"><h1>1</h1></div></div>
                        <Media query="(max-width: 520px)">
                            {matches =>
                                matches ? (
                                    <span className="h3-two">Создание<br /> техническо-<br />го задания</span>
                                ) : (
                                    <span className="h3-two">Создание<br /> технического<br />задания</span>
                                )
                            }
                        </Media>
                    </div>
                    <div className={active === 2 ? "item active" : "item"} onClick={() => setActive(2)}>
                        <div id="two" className="circle"><div className="inner"><h1>2</h1></div></div>
                        <Media query="(max-width: 520px)">
                            {matches =>
                                matches ? (
                                    <span className="h3-two">Проектирова-<br />ние UI / UX</span>
                                ) : (
                                    <span className="h3-two">Проектирование<br />UI / UX</span>
                                )
                            }
                        </Media>
                    </div>
                    <div className={active === 3 ? "item active" : "item"} onClick={() => setActive(3)}>
                        <div id="three" className="circle"><div className="inner"><h1>3</h1></div></div>
                        <span className="h3-two">Создание<br />концепции<br />дизайна</span>
                    </div>
                    <div className={active === 4 ? "item active" : "item"} onClick={() => setActive(4)}>
                        <div id="four" className="circle"><div className="inner"><h1>4</h1></div></div>
                        <span className="h3-two">Отрисовка<br />всех<br />экранов</span>
                    </div>
                    <div className={active === 5 ? "item active" : "item"} onClick={() => setActive(5)}>
                        <div id="five" className="circle"><div className="inner"><h1>5</h1></div></div>
                        <span className="h3-two">Програм-<br />мирование<br />и вертска</span>
                    </div>
                    <svg style={{ height: `${height1}px` }}>
                        <line id="line1" />
                    </svg>
                    <svg style={{ height: `${height1}px` }}>
                        <line id="line2" />
                    </svg>
                    <svg style={{ height: `${height1}px` }}>
                        <line id="line3" />
                    </svg>
                    <svg style={{ height: `${height1}px` }}>
                        <line id="line4" />
                    </svg>
                </div>
                <div className="lines">
                    <div className="line">
                        <img className={active === 1 ? "active" : ""} src={lines} alt="line" />
                    </div>
                    <div className="line">
                        <img className={active === 2 ? "active" : ""} src={lines} alt="line" />
                    </div>
                    <div className="line">
                        <img className={active === 3 ? "active" : ""} src={lines} alt="line" />
                    </div>
                    <div className="line">
                        <img className={active === 4 ? "active" : ""} src={lines} alt="line" />
                    </div>
                    <div className="line">
                        <img className={active === 5 ? "active" : ""} src={lines} alt="line" />
                    </div>
                </div>
            </div>
            <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                <div className="main-part">
                    <div className="container">
                        <div className="text">
                            {data[active - 1].text}
                        </div>
                    </div>
                </div>
            </AnimationOnScroll>
            <div className="lower-part">
                <div className="lines">
                    <div className="line">
                        <img className={active === 6 ? "active" : ""} src={lines} alt="line" />
                    </div>
                    <div className="line">
                        <img className={active === 7 ? "active" : ""} src={lines} alt="line" />
                    </div>
                    <div className="line">
                        <img className={active === 8 ? "active" : ""} src={lines} alt="line" />
                    </div>
                    <div className="line">
                        <img className={active === 9 ? "active" : ""} src={lines} alt="line" />
                    </div>
                    <div className="line">
                        <img className={active === 10 ? "active" : ""} src={lines} alt="line" />
                    </div>
                </div>
                <div className="items">
                    <div className={active === 6 ? "item active" : "item"} onClick={() => setActive(6)}>
                        <div id="six" className="circle"><div className="inner"><h1>6</h1></div></div>
                        <span className="h3-two">Тестирование</span>
                    </div>
                    <div className={active === 7 ? "item active" : "item"} onClick={() => setActive(7)}>
                        <div id="seven" className="circle"><div className="inner"><h1>7</h1></div></div>
                        <span className="h3-two">Отладка</span>
                    </div>
                    <div className={active === 8 ? "item active" : "item"} onClick={() => setActive(8)}>
                        <div id="eight" className="circle"><div className="inner"><h1>8</h1></div></div>
                        <span className="h3-two">Второе<br />тестирование</span>
                    </div>
                    <div className={active === 9 ? "item active" : "item"} onClick={() => setActive(9)}>
                        <div id="nine" className="circle"><div className="inner"><h1>9</h1></div></div>
                        <span className="h3-two">Создание<br />иконки<br />приложения</span>
                    </div>
                    <div className={active === 10 ? "item active" : "item"} onClick={() => setActive(10)}>
                        <div id="ten" className="circle"><div className="inner"><h1>10</h1></div></div>
                        <span className="h3-two">Публикация<br />в магазинах</span>
                    </div>
                    <svg style={{ height: `${height2}px` }}>
                        <line id="line5" />
                    </svg>
                    <svg style={{ height: `${height2}px` }}>
                        <line id="line6" />
                    </svg>
                    <svg style={{ height: `${height2}px` }}>
                        <line id="line7" />
                    </svg>
                    <svg style={{ height: `${height2}px` }}>
                        <line id="line8" />
                    </svg>
                </div>
            </div>
        </div >
    )
}

export default AllSteps
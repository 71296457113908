import React from 'react'
import { Helmet } from 'react-helmet'
import LandingText from '../../hooks/landingText/LandingText'
import AllSteps from './components/AllSteps/AllSteps'
import SecondPart from './components/SecondPart/SecondPart'
import StepsOfDevelopment from './components/StepsOfDevelopment/StepsOfDevelopment'
import './_AboutUs.scss'

const data = {
    h1: <header><h1>О компании</h1></header>,
    ul: <ul>
        <li><p>Компания ZOOM - это команда квалифицированных специалистов в области информационных технологий и маркетинга.</p></li>
        <li><p>Уже более 11 лет мы успешно увеличиваем доходы наших клиентов, автоматизируем бизнес - процессы, повышаем лояльность ваших заказчиков, улучшаем сервис и работаем на результат.</p></li>
        <li><p>Наша компания имеет офисы в Алматы и Астане. С 2007 года мы прошли путь от маленькой web студии до большой компании.</p></li>
        <li><p>Мы разрабатываем мобильные приложения в Алматы любого уровня сложнсости,предлагаем IT-решения, которые удивят даже самого искушенного клиента.</p></li>
        <li><p>Разрабатываем приложения для автоматизации бизнес-процессов на трех уровнях: в холдингах, компаниях и их структурных подразделениях.</p></li>
        <li><p>Мы строим долгосрочные взаимоотношения с партнерами, основанные на доверии и уважении. Команда наших специалистов прикладывает все усилия, чтобы подробно изучить и понять бизнес наших клиентов. Накопленные знания позволяют нам создавать максимально полезные программные продукты.</p></li>
        <li><p>У нас достаточно умений и опыта, чтобы выполнять сложные масштабные проекты.</p></li>
        <li><p>Клиенты компании «ZOOM» всегда стоят на первом месте. Нам небезразличен успех вашего бизнеса, и мы делаем все, чтобы он стал еще более эффективным и прибыльным.</p></li>
        <li><p>Мы любим свою работу, и нам нравится делать мир лучше, интереснее и мобильнее !</p></li>
        <li><p>Все условия сотрудничества мы закрепляем договором, который является вашей гарантией выполнения нами своих обязательств.</p></li>
    </ul>
}

function AboutUs() {
    return (
        <>
            <Helmet>
                <title>Веб студия «ZOOM TheAppLab»: Создание и разработка мобильных приложений и эффективных сайтов.</title>
                <meta
                    name="description"
                    content="Компания ZOOM - это команда квалифицированных специалистов в области информационных технологий и маркетинга. Уже более 11 лет мы успешно увеличиваем доходы наших клиентов, автоматизируем бизнес - процессы, повышаем лояльность ваших заказчиков, улучшаем сервис и работаем на результат."
                />
                <meta
                    name="keywords"
                    content="Разработка сайтов, Создание сайтов, Создание мобильного приложения, Разработка мобильного приложения, Алматы, Астана"
                />
            </Helmet>
            <main className='about-us-page'>
                <LandingText data={data} />
                <SecondPart />
                <StepsOfDevelopment />
                <AllSteps />
            </main>
        </>
    )
}

export default AboutUs
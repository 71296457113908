import React, { useState } from 'react'
import './Form.scss'
import axios from "axios";
import swal from 'sweetalert';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/input'

const baseURL = "https://zoomapps.kz/al2016kz.php";

function Form() {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState()

    const handleSubmit = (e) => {
        e.preventDefault();

        axios
            .post(baseURL, { event: 'callback', name, phone })
            .then(() => { //resp.data
                console.log('uspech')
                swal({
                    title: 'Успешно!',
                    text: `имя: ${name}\nномер телефона: ${phone}`,
                    icon: "success"
                });
            })
            .catch((error) => {
                console.log(error)
                swal({
                    title: 'Ошибка!',
                    text: error.message,
                    icon: "error"
                });
            });
    }

    return (
        <>
            <form onSubmit={(e) => handleSubmit(e)}>
                <input type="text" placeholder='Имя' value={name || ''} onInput={(e) => setName(e.target.value.replace(/[^A-Za-zА-Яа-яЁё ]{1,32}/ig, ''))} required />
                <PhoneInput
                    placeholder='Номер телефона' value={phone} onChange={setPhone} required />
                <input className='submit' type="submit" value="Оставить заявку" />
            </form>
        </>
    )
}

export default Form
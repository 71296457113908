import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';

function TranspContainer({ children }) {
    return (
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeftBig">
            <div className="transp-container">
                <div className="container">
                    {children}
                </div>
            </div>
        </AnimationOnScroll>
    )
}

export default TranspContainer
import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import Dropdown from './DropDown';

function MenuItems({ items }) {
    const [dropdown, setDropdown] = useState(false);
    let ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [dropdown]);

    const closeDropdown = () => {
        dropdown && setDropdown(false);
    };

    return (
        <li className="menu-items" ref={ref}
            onClick={closeDropdown}
        >
            {items.submenu ? (
                <>
                    <button type="button" aria-haspopup="menu"
                        className={`link ${dropdown ? "submenu" : ""}`}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {items.title}{' '}
                    </button>
                    <Dropdown submenus={items.submenu} dropdown={dropdown} />
                </>
            ) : (
                <Link to={items.url} className="link">{items.title}</Link>
            )}
        </li>
    )
}

export default MenuItems
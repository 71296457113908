import React from 'react'
import './Footer.scss'

import phone from '../components/images/svgs/footer/Phone.svg'
import mail from '../components/images/svgs/footer/Mail.svg'
import location from '../components/images/svgs/footer/Location.svg'
import mapImg from '../components/images/2gisMap.png'

import Map from './Map'
import { useLocation } from 'react-router-dom'

function Footer() {
    const loc = useLocation()

    return (
        <footer className="footer">
            {loc.pathname !== '/contacts' ?
                <>
                    <hr />
                    <div className="footer-container">
                        <div className="contacts">
                            <h1>Контакты</h1>
                            <div className="list">
                                <div className="item first">
                                    <img src={phone} alt="phone" />
                                    <a href="tel:87272960359" target="_blank" rel='noreferrer'>
                                        <h2>+7 727 296 03 59</h2>
                                        <div className="slider"></div>
                                    </a>
                                </div>
                                <div className="item second">
                                    <img src={phone} alt="phone" />
                                    <a href="tel:87057665444" target="_blank" rel='noreferrer'>
                                        <h2>+7 705 766 54 44</h2>
                                        <div className="slider"></div>
                                    </a>
                                </div>
                                <div className="item third">
                                    <img src={location} alt="Location" />
                                    <a href="https://2gis.ru/almaty/firm/9429940001532446/76.892081%2C43.243129?m=76.892124%2C43.243309%2F16" target="_blank" rel='noreferrer'>
                                        <h2>Казахстан, Алматы, БЦ “МТС”</h2>
                                        <div className="slider"></div>
                                    </a>
                                </div>
                                <div className="item fourth">
                                    <img src={mail} alt="Mail" />
                                    <a href="mailto:info@zoomapps.kz" target="_blank" rel='noreferrer'>
                                        <h2>
                                            info@zoomapps.kz
                                        </h2>
                                        <div className="slider"></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="map" >
                            <img src={mapImg} alt="map" />
                            <Map contacts={false} />
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="footer-container contacts">
                        <div className="contacts">
                            <h1>Контакты</h1>
                            <div className="list">
                                <div className="item">
                                    <img src={phone} alt="phone" />
                                    <a href="tel:87272960359" target="_blank" rel='noreferrer'>
                                        <h2>+7 727 296 03 59</h2>
                                        <div className="slider"></div>
                                    </a>
                                </div>
                                <div className="item">
                                    <img src={location} alt="Location" />
                                    <a href="https://2gis.ru/almaty/firm/9429940001532446/76.892081%2C43.243129?m=76.892124%2C43.243309%2F16" target="_blank" rel='noreferrer'>
                                        <h2>Казахстан, Алматы, БЦ “МТС”</h2>
                                        <div className="slider"></div>
                                    </a>
                                </div>
                                <div className="item">
                                    <img src={phone} alt="phone" />
                                    <a href="tel:87057665444" target="_blank" rel='noreferrer'>
                                        <h2>+7 705 766 54 44</h2>
                                        <div className="slider"></div>
                                    </a>
                                </div>
                                <div className="item">
                                    <img src={mail} alt="Mail" />
                                    <a href="mailto:info@zoomapps.kz" target="_blank" rel='noreferrer'>
                                        <h2>
                                            info@zoomapps.kz
                                        </h2>
                                        <div className="slider"></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="map" >
                            {/* <img src={mapImg} alt="map" /> */}
                            <Map contacts={true} />
                        </div>
                    </div>
                </>
            }
        </footer>
    )
}

export default Footer
import React from 'react'
import './_WebDev.scss'
import { Helmet } from 'react-helmet'
import FirstPart from './components/firstPart/FirstPart'
import AdvantagesOfZoom from './components/advantagesOfZoom/AdvantagesOfZoom'
import WhyClientsChooseZoom from './components/whyClientsChooseZoom/WhyClientsChooseZoom'
import OurProducts from './components/ourProducts/OurProducts'
import Form from '../main/Form'
import TranspContainer from '../../hooks/transpContainer/TranspContainer'

function WebDev() {
    return (
        <>
            <Helmet>
                <title>Создание сайтов в Алматы, разработка сайта, заказать услугу в веб студии ZOOM TheAppLab</title>
                <meta
                    name="description"
                    content="Создание сайтов под ключ в Алматы в студии веб-дизайна ZOOM TheAppLab. Разработка сайта, продвижение, портфолио. Лучшее соотношение цены к качеству."
                />
                <meta
                    name="keywords"
                    content="Разработка сайтов в Алматы, Создание сайтов в Алматы, Разработка сайтов в Астане, Создание сайтов в Астане"
                />
            </Helmet>
            <main className='web-dev-page'>
                <FirstPart />
                <AdvantagesOfZoom />
                <WhyClientsChooseZoom />
                <OurProducts />
                <TranspContainer>
                    <span className='h1-two'>Оставьте заявку и наши менеджеры свяжутся с Вами в ближайшее время</span>
                    <Form />
                </TranspContainer>
            </main>
        </>
    )
}

export default WebDev
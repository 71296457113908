import React from 'react'

import tick from '../../components/images/svgs/tick.svg'

function TickContainer(props) {
    return (
        <div className='tick-container'>
            <h1>{props.h1}</h1>
            <div className="tick-container__container">
                {props.data.map(({ text }, i) =>
                    <div className="tick-container__container__item" key={i}>
                        <img src={tick} alt="tick" />
                        {text}
                    </div>
                )}
            </div>
            {props.buttonClass === 'for-who__button' ?
                <button className='gradient' onClick={() => props.setOpen("open-content")}>
                    <div className='gradient__inner'>
                        {props.buttonText}
                    </div>
                </button>
                :
                <button className={props.buttonClass} onClick={() => props.setOpen("open-content")}>
                    {props.buttonText}
                </button>
            }
        </div>
    )
}

export default TickContainer
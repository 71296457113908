import React from 'react'
import './_LeaveRequest.scss'

import CloseButton from '../../components/images/svgs/CloseButton.svg'
import Form from '../../pages/main/Form'

function LeaveRequest({ open, setOpen }) {
    return (
        <>
            {open &&
                <div className="leave-request">
                    <div className={`leave-request__overlay ${open}`}>
                        <div className="leave-request__overlay__container">
                            <img className='leave-request__overlay__container__close-button' src={CloseButton} alt='close' onClick={() => setOpen("")} />
                            <div className="leave-request__overlay__container__content">
                                <span className='h1-two'>Оставьте заявку и наши менеджеры свяжутся с Вами в ближайшее время</span>
                                <Form />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default LeaveRequest
import React, { useState } from 'react'
import LeaveRequest from '../../../../hooks/leaveRequest/LeaveRequest'
import PriceList from '../../../../hooks/priceList/PriceList'

function Price() {
    const [open1, setOpen1] = useState("")
    const [open2, setOpen2] = useState("")
    const [open3, setOpen3] = useState("")

    return (
        <>
            <div className='price'>
                <PriceList
                    mainTitle={'Стоимость'}
                    first={{
                        title: 'Групповые занятия',
                        price: '68.500',
                        text: 'до 5 человек в группе 3 раза в неделю по 2 часа - 8 недель (Пн-Ср-Пт с 19-00 до 21-00)'
                    }}
                    second={{
                        title: 'Индивидуальные занятия',
                        price: '115.000',
                        text: 'с 9 до 18 00 в любое удобное 3 раза в неделю по 2 часа - 8 недель'
                    }}
                    third={{
                        title: 'Дистанционное обучение',
                        price: 'от 27.500',
                        text: ''
                    }}
                    setOpen1={setOpen1}
                    setOpen2={setOpen2}
                    setOpen3={setOpen3}
                />
            </div>
            {open1 &&
                <LeaveRequest open={open1} setOpen={setOpen1} />
            }
            {open2 &&
                <LeaveRequest open={open2} setOpen={setOpen2} />
            }
            {open3 &&
                <LeaveRequest open={open3} setOpen={setOpen3} />
            }
        </>
    )
}

export default Price
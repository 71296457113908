import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import OurProjects from '../../hooks/ourProjects/OurProjects'
import './ourWorks.scss'

function OurWorks() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>Наши работы по разработке мобильных приложений и сайтов - Портфолио веб-студии ZOOM TheAppLab</title>
                <meta
                    name="description"
                    content="Работы веб-студии ZOOM TheAppLab. Мы разрабатываем мобильные приложения в Алматы любого уровня сложнсости,предлагаем IT-решения, которые удивят даже самого искушенного клиента."
                />
                <meta
                    name="keywords"
                    content="дизайн, разработка мобильных приложений, сайтов, портфолио, веб сайт, алматы, наши работы, наши проекты"
                />
            </Helmet>
            <main className="page">
                <OurProjects hideShowAll={true} />
            </main>
        </>
    )
}

export default OurWorks
import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'

function PriceList(props) {
    return (
        <div className='price-list'>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                <h1>{props.mainTitle}</h1>
            </AnimationOnScroll>
            <div className="price-list__container">
                <AnimationOnScroll animateOnce={true} animateIn="animate__backInLeft">
                    <div className="price-list__container__item">
                        <div className="price-list__container__item__inside">
                            <div className="price-list__container__item__inside__content">
                                <p className="name">
                                    {props.first.title}
                                </p>
                                <hr />
                                <div className="h1-two">
                                    {props.first.price}
                                </div>
                                <h5>
                                    {props.first.text}
                                </h5>
                                <button className="gradient" onClick={() => props.setOpen1("open-content")}>
                                    <div className="gradient__inner">
                                        Оставить заявку
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateOnce={true} animateIn="animate__backInLeft">
                    <div className="price-list__container__item">
                        <div className="price-list__container__item__inside">
                            <div className="price-list__container__item__inside__content">
                                <p className="name">
                                    {props.second.title}
                                </p>
                                <hr />
                                <div className="h1-two">
                                    {props.second.price}
                                </div>
                                <h5>
                                    {props.second.text}
                                </h5>
                                <button className="gradient" onClick={() => props.setOpen2("open-content")}>
                                    <div className="gradient__inner">
                                        Оставить заявку
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateOnce={true} animateIn="animate__backInLeft">
                    <div className="price-list__container__item">
                        <div className="price-list__container__item__inside">
                            <div className="price-list__container__item__inside__content">
                                <p className="name">
                                    {props.third.title}
                                </p>
                                <hr />
                                <div className="h1-two">
                                    {props.third.price}
                                </div>
                                <h5>
                                    {props.third.text}
                                </h5>
                                <button className="gradient" onClick={() => props.setOpen3("open-content")}>
                                    <div className="gradient__inner">
                                        Оставить заявку
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </AnimationOnScroll>
            </div>
        </div>
    )
}

export default PriceList
import React, { useEffect } from 'react'

import DG from '2gis-maps'
import Media from "react-media"

import mapIcon from '../components/images/MapIcon.png'

function Map({ contacts }) {
    useEffect(() => {
        let map
        const [LAT, LONG] = [43.243309, 76.892124]

        map = DG.map('map-container', {
            center: [LAT, LONG],
            zoom: 16
        })

        const icon = DG.icon({
            iconUrl: mapIcon,
            iconSize: [40, 40]
        })

        DG.marker([LAT, LONG], { icon: icon }).addTo(map).bindPopup('БЦ "MTC"')
    }, [])

    return (
        <>
            {contacts ?
                <>
                    <Media queries={{
                        a: { maxWidth: 520 },
                        b: { maxWidth: 648 },
                        c: { maxWidth: 1115 }
                    }}>
                        {matches =>
                            matches.c ?
                                matches.b ?
                                    matches.a ?
                                        (<div id="map-container" style={{ width: '345px', height: '224px', borderRadius: '20px' }}></div>)
                                        :
                                        (<div id="map-container" style={{ width: '483px', height: '314px', borderRadius: '20px' }}></div>)
                                    :
                                    (<div id="map-container" style={{ width: '600px', height: '390px', borderRadius: '20px' }}></div>)
                                :
                                (<div id="map-container" style={{ width: '1115px', height: '720px', borderRadius: '20px' }}></div>)
                        }
                    </Media>
                </>
                :
                <Media query="(max-width: 520px)">
                    {matches =>
                        matches ? (
                            <div id="map-container" style={{ width: '345px', height: '224px', borderRadius: '20px' }}></div>
                        ) : (
                            <div id="map-container" style={{ width: '483px', height: '314px', borderRadius: '20px' }}></div>
                        )
                    }
                </Media>
            }
        </>
    )
}

export default Map
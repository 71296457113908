import React from 'react'

import TickContainer from '../../../../hooks/tickContainer/TickContainer'

const data = [
    {
        text: <h5>Разрабатывать идеи и структуру мобильных приложений для iPhone, iPad и Apple Watch</h5>
    },
    {
        text: <h5>Самостоятельно программировать на языке Swift, разрабатывать современные мобильные приложения</h5>
    },
    {
        text: <h5>Размещать приложения на App Store</h5>
    },
    {
        text: <h5>Верстать дизайн-макеты в настоящие приложения</h5>
    },
    {
        text: <h5>Работать с Rest Api (взаимодествие приложения с сервером)</h5>
    },
    {
        text: <h5>Оценивать трудозатраты на реализацию функционала.</h5>
    },
    {
        text: <h5>Быстро превращать свои задумки в работающие прототипы</h5>
    },
    {
        text: <h5>Тестировать и отлаживать приложения</h5>
    }
]

function WhatIWillLearn({ setOpen }) {
    return (
        <div className='what-i-will-learn'>
            <TickContainer h1={'Чему я научусь?'} data={data} buttonCLass={'btn-white'} buttonText={'Оставить заявку'} setOpen={setOpen} />
        </div>
    )
}

export default WhatIWillLearn
import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'

import forbes_logo from '../../../../components/images/study-page/Forbes_logo.svg'

function SecondPart() {
    return (
        <div className='second-part'>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeftBig">
                <div className="first-container">
                    <div className="video">
                        <iframe
                            width="576"
                            height="324"
                            src={'https://www.youtube.com/embed/R7qTXIBeRr0'}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    </div>
                    <div className="text">
                        <p><span className='bold'>ZOOM</span> - Одна из группы Digital компаний, являющяяся лидером в области разработки мобильных приложений и игр на рынке Казахстана объявляет набор на курсы по IOS программированию.<br /><br />

                            11 лет опыта в сфере IT разработок и интернет маркетинга. Более 300 реализованых проектов. В нашей компании работают только лучшие, сильнейшие программисты Казахстана,которые и будут вести данный курс.
                        </p>
                    </div>
                </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRightBig">
                <div className="second-container">
                    <h1>Казахстанский рынок труда и государство заинтересовано в новых программистах.</h1>
                    <a href='https://forbes.kz/process/businessmen/kak_sdelat_biznes_vlojiv_zarplatu' target='_blank' rel='noreferrer'>
                        <button>
                            <svg>
                                <line x1="20%" y1="50%" x2="80%" y2="50%" />
                            </svg>
                            <div className="forbes-container">
                                <img src={forbes_logo} alt="forbes logo" />
                                <p>Читать статью forbes.kz</p>
                            </div>
                            <svg >
                                <line x1="20%" y1="50%" x2="80%" y2="50%" />
                            </svg>
                        </button>
                    </a>
                </div>
            </AnimationOnScroll>
        </div>
    )
}

export default SecondPart
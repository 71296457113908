import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import LandingText from '../../hooks/landingText/LandingText'
import LeaveRequest from '../../hooks/leaveRequest/LeaveRequest'
import TranspContainer from '../../hooks/transpContainer/TranspContainer'
import DemandsForStudents from './components/demandsForStudents/DemandsForStudents'
import ForWho from './components/forWho/ForWho'
import Price from './components/Price/Price'
import SecondPart from './components/secondPart/SecondPart'
import StarsOfProfession from './components/starsOfProfession/StarsOfProfession'
import WhatIWillLearn from './components/whatIWillLearn/WhatIWillLearn'
import WhatsIncluded from './components/whatsIncluded/WhatsIncluded'
import WhoMadeACourse from './components/whoMadeACourse/WhoMadeACourse'
import WhyBecomeIOSDev from './components/whyBecomeIOSDev/WhyBecomeIOSDev'
import './_Study.scss'

const data = {
    h1: <header><h1>Научим создавать мобильные приложения на iPhone и iPad за  2 месяца</h1></header>,
    ul: <ul>
        <li><p>Научим создавать уникальные мобильные приложения для iPHONE и iPAD в Алматы за 2 месяца: продумывать структуру проекта и самостоятельно программировать на языке <span className='bold'>Swift под IOS.</span></p></li>
        <li><p>Сотни миллионов людей каждый день пользуются iPhone, iPad и Apple Watch, а приложения для IOS являются <span className='bold'>самыми доходными на мобильном рынке.</span> Apple, представив миру свой новый язык программирования Swift, сделали создание мобильных приложений для App Store максимально простым и удобным.</p></li>
        <li><p><span className='bold'>На  интенсивных курсах обучения программированию</span> под операционную системы IOS от компании ZOOM,проходящих в городе Алматы,Вы освоите одну из самых прибыльных и перспективных профессий на рынке Казахстана – <span className='bold'>IOS-разработчик.</span></p></li>
    </ul>
}

function Study() {
    const [open, setOpen] = useState("")

    return (
        <>
            <Helmet>
                <title>Научим создавать мобильные приложения на iPhone и iPad за 2 месяца</title>
                <meta
                    name="description"
                    content="На интенсивных курсах обучения программированию под операционную системы IOS от компании ZOOM, проходящих в городе Алматы, Вы освоите одну из самых прибыльных и перспективных профессий на рынке Казахстана – IOS-разработчик."
                />
                <meta
                    name="keywords"
                    content="обучение программированию, интенсивные курсы, курсы по созданию побильных приложений, программирование на языке Swift под IOS"
                />
            </Helmet>
            <main className='study-page'>
                <LandingText data={data} />
                <SecondPart />
                <TranspContainer>
                    <WhatIWillLearn setOpen={setOpen} />
                </TranspContainer>
                <DemandsForStudents />
                <StarsOfProfession />
                <ForWho />
                <WhyBecomeIOSDev />
                <WhoMadeACourse />
                <Price />
                <WhatsIncluded />
            </main>
            {open &&
                <LeaveRequest open={open} setOpen={setOpen} />
            }
        </>
    )
}

export default Study
import React from 'react'

import { AnimationOnScroll } from 'react-animation-on-scroll';

import A from '../../../../components/images/svgs/about-us/second-part/A.svg'
import B from '../../../../components/images/svgs/about-us/second-part/B.svg'
import C from '../../../../components/images/svgs/about-us/second-part/C.svg'
import D from '../../../../components/images/svgs/about-us/second-part/D.svg'
import E from '../../../../components/images/svgs/about-us/second-part/E.svg'
import F from '../../../../components/images/svgs/about-us/second-part/F.svg'

function SecondPart() {
    return (
        <div className='second-part'>
            <div className="container">
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRightBig">
                    <div className="item">
                        <img src={A} alt="phone" />
                        <span className='h2-two'>Создаем мобильные приложения для любых устройств
                        </span>
                    </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateOnce={true} animateIn="animate__flip">
                    <div className="item">
                        <img src={B} alt="check" />
                        <span className='h2-two'>Тщательно тестируем результат каждого этапа</span>
                    </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeftBig">
                    <div className="item">
                        <img src={C} alt="lamp" />
                        <span className='h2-two'>Поможем оценить идею
                            до начала работы</span>
                    </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRightBig">
                    <div className="item">
                        <img src={D} alt="email" />
                        <span className='h2-two'>Съели собаку в  IT технологиях</span>
                    </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateOnce={true} animateIn="animate__flip">
                    <div className="item">
                        <img src={E} alt="world" />
                        <span className='h2-two'>11 лет работы с клиентами по всему миру</span>
                    </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeftBig">
                    <div className="item">
                        <img src={F} alt="states" />
                        <span className='h2-two'>Прозрачный для клиента процесс разработки</span>
                    </div>
                </AnimationOnScroll>
            </div>
        </div>
    )
}

export default SecondPart
import React, { useEffect, useState } from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'

import lines from '../../../../../components/images/svgs/about-us/all-steps/lines.svg'

function DotsContent() {
    const [active, setActive] = useState(3)
    const [height1, setHeight1] = useState(0)

    useEffect(() => {
        let timer1 = setTimeout(() => {

            let line1 = document.getElementById("line1");
            let line2 = document.getElementById("line2");
            let line3 = document.getElementById("line3");
            let line4 = document.getElementById("line4");

            let div1 = document.getElementById("one");
            let div2 = document.getElementById("two");
            let div3 = document.getElementById("three");
            let div4 = document.getElementById("four");
            let div5 = document.getElementById("five");

            let x1s = div1.offsetLeft + (div1.offsetWidth) + 7;
            let y1s = div1.offsetTop + (div1.offsetHeight / 2);
            let x2e = div2.offsetLeft - 7;
            let y2e = div2.offsetTop + (div2.offsetHeight / 2);

            let x2s = div2.offsetLeft + (div2.offsetWidth) + 7;
            let y2s = div2.offsetTop + (div2.offsetHeight / 2);
            let x3e = div3.offsetLeft - 7;
            let y3e = div3.offsetTop + (div3.offsetHeight / 2);

            let x3s = div3.offsetLeft + (div3.offsetWidth) + 7;
            let y3s = div3.offsetTop + (div3.offsetHeight / 2);
            let x4e = div4.offsetLeft - 7;
            let y4e = div4.offsetTop + (div4.offsetHeight / 2);

            let x4s = div4.offsetLeft + (div4.offsetWidth) + 7;
            let y4s = div4.offsetTop + (div4.offsetHeight / 2);
            let x5e = div5.offsetLeft - 7;
            let y5e = div5.offsetTop + (div5.offsetHeight / 2);

            var height1 = div1.offsetTop + div1.offsetHeight;

            setHeight1(height1)

            line1.setAttribute('x1', x1s)
            line1.setAttribute('y1', y1s)
            line1.setAttribute('x2', x2e)
            line1.setAttribute('y2', y2e)

            line2.setAttribute('x1', x2s)
            line2.setAttribute('y1', y2s)
            line2.setAttribute('x2', x3e)
            line2.setAttribute('y2', y3e)

            line3.setAttribute('x1', x3s)
            line3.setAttribute('y1', y3s)
            line3.setAttribute('x2', x4e)
            line3.setAttribute('y2', y4e)

            line4.setAttribute('x1', x4s)
            line4.setAttribute('y1', y4s)
            line4.setAttribute('x2', x5e)
            line4.setAttribute('y2', y5e)
        }, 1000);

        return () => {
            clearTimeout(timer1);
        }
    }, [])

    useEffect(() => {
        let line1 = document.getElementById("line1");
        let line2 = document.getElementById("line2");
        let line3 = document.getElementById("line3");
        let line4 = document.getElementById("line4");

        let div1 = document.getElementById("one");
        let div2 = document.getElementById("two");
        let div3 = document.getElementById("three");
        let div4 = document.getElementById("four");
        let div5 = document.getElementById("five");
        const handleResize2 = () => {

            let x1s = div1.offsetLeft + (div1.offsetWidth) + 7;
            let y1s = div1.offsetTop + (div1.offsetHeight / 2);
            let x2e = div2.offsetLeft - 7;
            let y2e = div2.offsetTop + (div2.offsetHeight / 2);

            let x2s = div2.offsetLeft + (div2.offsetWidth) + 7;
            let y2s = div2.offsetTop + (div2.offsetHeight / 2);
            let x3e = div3.offsetLeft - 7;
            let y3e = div3.offsetTop + (div3.offsetHeight / 2);

            let x3s = div3.offsetLeft + (div3.offsetWidth) + 7;
            let y3s = div3.offsetTop + (div3.offsetHeight / 2);
            let x4e = div4.offsetLeft - 7;
            let y4e = div4.offsetTop + (div4.offsetHeight / 2);

            let x4s = div4.offsetLeft + (div4.offsetWidth) + 7;
            let y4s = div4.offsetTop + (div4.offsetHeight / 2);
            let x5e = div5.offsetLeft - 7;
            let y5e = div5.offsetTop + (div5.offsetHeight / 2);

            var height1 = div1.offsetTop + div1.offsetHeight;

            setHeight1(height1)

            line1.setAttribute('x1', x1s)
            line1.setAttribute('y1', y1s)
            line1.setAttribute('x2', x2e)
            line1.setAttribute('y2', y2e)

            line2.setAttribute('x1', x2s)
            line2.setAttribute('y1', y2s)
            line2.setAttribute('x2', x3e)
            line2.setAttribute('y2', y3e)

            line3.setAttribute('x1', x3s)
            line3.setAttribute('y1', y3s)
            line3.setAttribute('x2', x4e)
            line3.setAttribute('y2', y4e)

            line4.setAttribute('x1', x4s)
            line4.setAttribute('y1', y4s)
            line4.setAttribute('x2', x5e)
            line4.setAttribute('y2', y5e)
        }

        window.addEventListener('resize', handleResize2)

        return () => {
            window.removeEventListener('resize', handleResize2)

        }
    }, [])

    return (
        <div className='dots-content'>
            <div className="upper-part">
                <div className="items">
                    <div className={active === 1 ? "item active" : "item"} onClick={() => setActive(1)}>
                        <div id="one" className="circle"><div className="inner"><h1>1</h1></div></div>
                        {active === 1 ?
                            <img className="active" src={lines} alt="line" />
                            :
                            <span className="h3-two first">Сертификат</span>
                        }
                    </div>
                    <div className={active === 2 ? "item active" : "item"} onClick={() => setActive(2)}>
                        <div id="two" className="circle"><div className="inner"><h1>2</h1></div></div>
                        {active === 2 ?
                            <img className="active" src={lines} alt="line" />
                            :
                            <span className="h3-two">Видеозаписи<br /> всех уроков</span>
                        }
                    </div>
                    <div className={active === 3 ? "item active" : "item"} onClick={() => setActive(3)}>
                        <div id="three" className="circle"><div className="inner"><h1>3</h1></div></div>
                        {active === 3 ?
                            <img className="active" src={lines} alt="line" />
                            :
                            <span className="h3-two">Разбор кейсов</span>
                        }
                    </div>
                    <div className={active === 4 ? "item active" : "item"} onClick={() => setActive(4)}>
                        <div id="four" className="circle"><div className="inner"><h1>4</h1></div></div>
                        {active === 4 ?
                            <img className="active" src={lines} alt="line" />
                            :
                            <span className="h3-two">Нетворкинг</span>
                        }
                    </div>
                    <div className={active === 5 ? "item active" : "item"} onClick={() => setActive(5)}>
                        <div id="five" className="circle"><div className="inner"><h1>5</h1></div></div>
                        {active === 5 ?
                            <img className="active" src={lines} alt="line" />
                            :
                            <span className="h3-two last">Учебные материалы</span>
                        }
                    </div>
                    <svg style={{ height: `${height1}px` }}>
                        <line id="line1" />
                    </svg>
                    <svg style={{ height: `${height1}px` }}>
                        <line id="line2" />
                    </svg>
                    <svg style={{ height: `${height1}px` }}>
                        <line id="line3" />
                    </svg>
                    <svg style={{ height: `${height1}px` }}>
                        <line id="line4" />
                    </svg>
                </div>
                <div className="content">
                    {active === 1 &&
                        <div className="h3-two first"><span className='bold'>Разбор кейсов и общение в закрытой группе:</span><br />
                            поддержка и консультация между занятиями
                            файлы с кодом всех практических проектов</div>
                    }
                    {active === 2 &&
                        <div className="h3-two second"><span className='bold'>Разбор кейсов и общение в закрытой группе:</span><br />
                            поддержка и консультация между занятиями
                            файлы с кодом всех практических проектов</div>
                    }
                    {active === 3 &&
                        <div className="h3-two third"><span className='bold'>Разбор кейсов и общение в закрытой группе:</span><br />
                            поддержка и консультация между занятиями
                            файлы с кодом всех практических проектов</div>
                    }
                    {active === 4 &&
                        <div className="h3-two fourth"><span className='bold'>Разбор кейсов и общение в закрытой группе:</span><br />
                            поддержка и консультация между занятиями
                            файлы с кодом всех практических проектов</div>
                    }
                    {active === 5 &&
                        <div className="h3-two last"><span className='bold'>Разбор кейсов и общение в закрытой группе:</span><br />
                            поддержка и консультация между занятиями
                            файлы с кодом всех практических проектов</div>
                    }
                </div>
            </div>
            <AnimationOnScroll animateOnce={true} animateIn="animate__backInDown">
                <div className="block">
                    <div className="inner">
                        <div className="h3-two">
                            <span className='bold'>Компания «ZOOM»</span> по завершению курса трудоустроит к себе
                            в компанию 2 лучших учеников, прошедших курс с отличием.
                        </div>
                    </div>
                </div>
            </AnimationOnScroll>
        </div>
    )
}

export default DotsContent
import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'

import A from '../../../../components/images/study-page/stars-of-profession/A.png'
import B from '../../../../components/images/study-page/stars-of-profession/B.png'
import C from '../../../../components/images/study-page/stars-of-profession/C.png'

function StarsOfProfession() {
    return (
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUpBig">
            <div className='stars-of-profession'>
                <h1>Звезды профессии</h1>
                <div className="stars-of-profession__container">
                    <div className="stars-of-profession__container__item">
                        <img src={A} alt="director of instagram" />
                        <div className="h3-two">Основатель и генеральный директор instagram</div>
                    </div>
                    <div className="stars-of-profession__container__item">
                        <img src={B} alt="founder of instagram" />
                        <div className="h3-two">Сооснователь Instagram, руководитель группы разработки приложения</div>
                    </div>
                    <div className="stars-of-profession__container__item">
                        <img src={C} alt="director of whatsapp" />
                        <div className="h3-two">Основатель и генеральный директор WhatsApp</div>
                    </div>
                </div>
            </div>
        </AnimationOnScroll>
    )
}

export default StarsOfProfession
import React, { useState } from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'

import notebook from '../../../../components/images/WebDev-page/FirstPart/Notebook.png'
import LeaveRequest from '../../../../hooks/leaveRequest/LeaveRequest'

function FirstPart() {
    const [open, setOpen] = useState("")

    return (
        <>
            <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                <div className='first-part'>
                    <div className="first-part__left">
                        <div className="first-part__left__upper">
                            <header>
                                <h1>Разработка сайтов</h1>
                            </header>
                            <h2>Быстрые и функциональные решения для вашего бизнеса</h2>
                            <h2 className='bold'>
                                Оставь заявку и получи бесплатную консультацию!</h2>
                        </div>
                        <button className='gradient' onClick={() => setOpen("open-content")}>
                            <span className='gradient__inner'>Оставить заявку</span>
                        </button>
                    </div>
                    <img src={notebook} alt="notebook" />
                </div>
            </AnimationOnScroll>
            {open &&
                <LeaveRequest open={open} setOpen={setOpen} />
            }
        </>
    )
}

export default FirstPart
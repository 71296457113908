import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'

function DateAndTime(props) {
    return (
        <AnimationOnScroll animateOnce={true} animateIn="animate__heartBeat">
            <div className='date-and-time'>
                <p className="name">Даты и время занятий</p>
                <h5 className='small-title'>Дата начала: 15 Марта 2018 г.</h5>
                <div className="date-and-time__container">
                    <div className="date-and-time__container__left">
                        <h5><span className='bold'>Групповые занятия:<br /></span>
                            Пн-Ср-Пт: с 19:00 до 21:00</h5>
                        <h5><span className='bold'>Индивидуальные занятия:<br /></span>
                            Пн-Вт-Ср-Чт-Пт: с 9:00 до 18:00<br /> время и дни подбираются<br /> индивидуально.</h5>
                    </div>
                    <div className="date-and-time__container__right">
                        <h5><span className='bold'>Место проведения<br /></span>
                            Алматы, улица Байзакова,<br /> 194, офис 600 , 6 этаж</h5>
                        <h5>
                            <a href="https://go.2gis.com/4ma6t" target='_blank' rel='noreferrer'>
                                <span className='bold'>Посмотреть на карте</span>
                            </a>
                        </h5>
                    </div>
                </div>
                <button className="gradient" onClick={() => props.setOpen("open-content")}>
                    <div className="gradient__inner">
                        Оставить заявку
                    </div>
                </button>
            </div>
        </AnimationOnScroll>
    )
}

export default DateAndTime
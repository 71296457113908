import React from 'react'

import macbook from '../../../../components/images/svgs/demands-for-students/macbook.svg'
import knowledge from '../../../../components/images/svgs/demands-for-students/knowledge.svg'
import grow from '../../../../components/images/svgs/demands-for-students/grow.svg'
import pc from '../../../../components/images/svgs/demands-for-students/pc.svg'
import plusIcon from '../../../../components/images/svgs/demands-for-students/plus-icon.svg'
import { AnimationOnScroll } from 'react-animation-on-scroll'

function DemandsForStudents() {
    return (
        <div className='demands-for-students'>
            <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                <h1>Требования к ученикам</h1>
            </AnimationOnScroll>
            <div className="demands-for-students__container">
                <div className="demands-for-students__container__item">
                    <AnimationOnScroll animateOnce={true} animateIn="animate__rubberBand">
                        <img src={macbook} alt="macbook" />
                    </AnimationOnScroll>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                        <div className="h3-two">Иметь свой
                            MacBook</div>
                    </AnimationOnScroll>
                </div>
                <div className="demands-for-students__container__item">
                    <AnimationOnScroll animateOnce={true} animateIn="animate__rubberBand">
                        <img src={knowledge} alt="knowledge" />
                    </AnimationOnScroll>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                        <div className="h3-two">Базовые знания аглийского языка на уровне Elementary</div>
                    </AnimationOnScroll>
                </div>
                <div className="demands-for-students__container__item">
                    <AnimationOnScroll animateOnce={true} animateIn="animate__rubberBand">
                        <img src={grow} alt="grow" />
                    </AnimationOnScroll>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                        <div className="h3-two">Желание учиться
                            и развиваться
                        </div>
                    </AnimationOnScroll>
                </div>
                <div className="demands-for-students__container__item">
                    <AnimationOnScroll animateOnce={true} animateIn="animate__rubberBand">
                        <img src={pc} alt="pc" />
                    </AnimationOnScroll>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                        <div className="h3-two">Уверенное владение
                            персональным компютером</div>
                    </AnimationOnScroll>
                </div>
            </div>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeftBig">
                <div className="demands-for-students__plus">
                    <img src={plusIcon} alt="plus icon" />
                    <h5>Понимание базовых принципов объекто-ориентированного программирование будет плюсом.</h5>
                </div>
            </AnimationOnScroll>
        </div>
    )
}

export default DemandsForStudents
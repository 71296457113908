import React, { useState } from 'react'
import LeaveRequest from '../../../../hooks/leaveRequest/LeaveRequest'
import PriceList from '../../../../hooks/priceList/PriceList'

function OurProducts() {
    const [open1, setOpen1] = useState("")
    const [open2, setOpen2] = useState("")
    const [open3, setOpen3] = useState("")

    return (
        <>
            <div className='our-products'>
                <PriceList
                    mainTitle={'Наши продукты'}
                    first={{
                        title: 'Landing page',
                        price: 'от 75.000',
                        text: 'Уникальный Или Шаблонный дизайн сайта (1 длинная стр.) 10 функциональных модуль Система управления сайтом (CMS) Базовое наполнение сайта Обучение работе с сайтом Добавление сайта в Google, Яндекс'
                    }}
                    second={{
                        title: 'Корпоративный сайт',
                        price: 'от 130.000',
                        text: 'Уникальный или шаблонный дизайн сайта (4-5 стр.) 21 функциональный модуль Система управления сайтом (CMS) Базовое наполнение сайта Обучение работе с сайтом Добавление сайта в Google, Яндекс'
                    }}
                    third={{
                        title: 'Интернет магазин',
                        price: 'от 130.000',
                        text: 'Уникальный дизайн сайта (5 стр.) 27 функциональных модуля Система управления сайтом (CMS) Базовое наполнение сайта Обучение работе с сайтом Добавление сайта в Google, Яндекс Каталог продукции Карточка товара'
                    }}
                    setOpen1={setOpen1}
                    setOpen2={setOpen2}
                    setOpen3={setOpen3}
                />
            </div>
            {open1 &&
                <LeaveRequest open={open1} setOpen={setOpen1} />
            }
            {open2 &&
                <LeaveRequest open={open2} setOpen={setOpen2} />
            }
            {open3 &&
                <LeaveRequest open={open3} setOpen={setOpen3} />
            }
        </>
    )
}

export default OurProducts
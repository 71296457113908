import React from 'react'
import { Link } from 'react-router-dom'

function DropDown({ submenus, dropdown }) {
    return (
        <ul className={`dropdown ${dropdown ? "show" : ""}`}>
            {submenus.map((submenu, index) => (
                <li key={index} className="menu-items">
                    <Link to={submenu.url} className="link menu-item">{submenu.title}</Link>
                </li>
            ))}
        </ul>
    )
}

export default DropDown
import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';

function LandingText({ data }) {
    return (
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeftBig">
            <div className='landing-text'>
                {data.h1}
                {data.ul}
            </div>
        </AnimationOnScroll>
    )
}

export default LandingText
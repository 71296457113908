import './App.scss';
import { Routes, Route, Navigate } from 'react-router-dom'
import Main from './pages/main/main'
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import OurWorks from './pages/ourWorks/OurWorks';
import AboutUs from './pages/aboutUs/AboutUs';
import Study from './pages/study/Study';
import WebDev from './pages/webDev/WebDev';

import "animate.css/animate.min.css";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Main />} />
        <Route path="/our-works" exact element={<OurWorks />} />
        <Route path="/about-us" exact element={<AboutUs />} />
        <Route path="/study" exact element={<Study />} />
        <Route path="/web-dev" exact element={<WebDev />} />
        <Route path="/contacts" exact />
        <Route path="*" element={<Navigate to='/' />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

import React, { useEffect, useRef, useState } from 'react'
import logo from '../components/images/svgs/logo.svg'
import nav from '../components/images/svgs/Burger.svg'
import './Navbar.scss'

import { menuItems } from './menuItems_list';
import MenuItems from './MenuItems';
import Navigation from './Navigation';

function Navbar() {
    const [navig, setNavig] = useState(false)
    let ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (navig && ref.current && !ref.current.contains(event.target)) {
                setNavig(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [navig]);

    const closeNav = () => {
        navig && setNavig(false);
    };

    return (
        <nav className='navbar'>
            <div className='navbar-container'>
                <img alt='logo' src={logo} />
                <ul className='ul'>
                    {menuItems.map((menu, index) => {
                        return <MenuItems items={menu} key={index} />;
                    })}
                </ul>
                <div className="navigation">
                    <img src={nav} alt="navigation"
                        onClick={() => setNavig((prev) => !prev)}
                    />
                    {navig &&
                        <ul className='navig-ul' ref={ref}>
                            {menuItems.map((menu, index) => {
                                return <Navigation items={menu} key={index} closeNav={closeNav} />;
                            })}
                        </ul>
                    }
                </div>
            </div>
            <hr />
        </nav>
    )
}

export default Navbar

import React, { useState } from 'react'

function DotsNav({ quantity, setPage, myref }) {

    const [active, setActive] = useState(1)

    const arr = Array.from({ length: quantity }, (_, i) => i + 1)

    const makeActive = (e) => {
        let i = e.target.getAttribute('i')
        setActive(parseInt(i))
        setPage(i - 1)
        myref.current?.scrollIntoView()
    }

    return (
        <ul className="dots">
            {arr.map((item) => (
                <li key={item} i={item} className={active === item ? "active" : ""}
                    onClick={(e) => { makeActive(e) }}></li>
            ))
            }
        </ul>
    )
}

export default DotsNav

import React from 'react'

import tick from '../../../../components/images/svgs/tick.svg'
import Ivan from '../../../../components/images/study-page/Ivan.png'
import { AnimationOnScroll } from 'react-animation-on-scroll'

function WhoMadeACourse() {
    return (
        <div className='who-made-a-course'>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                <h1>Создатели и ведущие курса</h1>
            </AnimationOnScroll>
            <div className="who-made-a-course__container">
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft">
                    <div className="who-made-a-course__container__left">
                        <img src={Ivan} alt="Ivan" />
                        <p className='name'>Востриков Иван</p>
                        <p>Основатель группы IT-компаний
                            и директор компании ZOOM.
                            В прошлом главный IOS разработчик компании ZOOM.</p>
                    </div>
                </AnimationOnScroll>
                <div className="who-made-a-course__container__right">
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight">
                        <div className="who-made-a-course__container__right__item">
                            <img src={tick} alt="tick" />
                            <p>Предприниматель - За 11 лет коммерческой деятельности, Иван основал 4 IT компании,которые занимают лидирующие позиции на рынке в своем сегменте.</p>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight">
                        <div className="who-made-a-course__container__right__item">
                            <img src={tick} alt="tick" />
                            <p>Маркетолог - Иван сам занимается маркетингом всех своих компаний. И в скором времени планирует открыть соответсвующие курсы Digital Маркетинга в Казахстане.</p>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight">
                        <div className="who-made-a-course__container__right__item">
                            <img src={tick} alt="tick" />
                            <p>Зарубежный опыт - Наш директор,регулярно посещает страны СНГ,Европы и США для повышения своей квалификации и генерации новых идей.</p>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight">
                        <div className="who-made-a-course__container__right__item">
                            <img src={tick} alt="tick" />
                            <p>Успешно обучил 16 человек программированию
                                на SWIFT за 2 месяца</p>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight">
                        <div className="who-made-a-course__container__right__item">
                            <img src={tick} alt="tick" />
                            <p>Более 5 лет опыта программирования под IOS</p>
                        </div>
                    </AnimationOnScroll>
                </div>
            </div>
        </div >
    )
}

export default WhoMadeACourse
export const menuItems = [
    {
        title: 'Главная',
        url: '/',
    },
    {
        title: 'О Нас',
        url: '/about-us',
    },
    {
        title: 'Наши Работы',
        url: '/our-works',
    },
    {
        title: 'Услуги',
        submenu: [
            {
                title: 'Обучение',
                url: '/study',
            },
            {
                title: 'Разработка сайта',
                url: '/web-dev',
            },
        ],
    },
    {
        title: 'Контакты',
        url: '/contacts',
    }
];
import React, { useState } from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import LeaveRequest from '../../../../hooks/leaveRequest/LeaveRequest'
import DateAndTime from './DateAndTime/DateAndTime'
import DotsContent from './DotsContent/DotsContent'

function WhatsIncluded() {
    const [open, setOpen] = useState("")

    return (
        <>
            <div className='whats-included'>
                <AnimationOnScroll animateOnce={true} animateIn="animate__backInUp">
                    <h1>Что входит в стоимость</h1>
                </AnimationOnScroll>
                <DotsContent />
                <DateAndTime setOpen={setOpen} />
            </div>
            {open &&
                <LeaveRequest open={open} setOpen={setOpen} />
            }
        </>
    )
}

export default WhatsIncluded
import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'

import A from '../../../../components/images/WebDev-page/AdvantagesOfZoom/A.png'
import B from '../../../../components/images/WebDev-page/AdvantagesOfZoom/B.png'
import C from '../../../../components/images/WebDev-page/AdvantagesOfZoom/C.png'
import D from '../../../../components/images/WebDev-page/AdvantagesOfZoom/D.png'
import E from '../../../../components/images/WebDev-page/AdvantagesOfZoom/E.png'
import F from '../../../../components/images/WebDev-page/AdvantagesOfZoom/F.png'

function AdvantagesOfZoom() {
    return (
        <div className='advantages-of-zoom'>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                <h1>Преимущества компании ZOOM</h1>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInDown">
                <div className="advantages-of-zoom__container">
                    <div className="advantages-of-zoom__container__inner">
                        <div className="advantages-of-zoom__container__inner__item">
                            <img src={A} alt="рукопожатие" />
                            <h3 className='h3-two'>
                                Доступная стоимость для любого бизнеса
                            </h3>
                        </div>
                        <div className="advantages-of-zoom__container__inner__item">
                            <img src={B} alt="дедлайн" />
                            <h3 className='h3-two'>
                                Точное соблюдение сроков сдачи проекта
                            </h3>
                        </div>
                        <div className="advantages-of-zoom__container__inner__item">
                            <img src={C} alt="сроки" />
                            <h3 className='h3-two'>
                                Кратчайшие сроки разработки<br />Запуск сайта от 5 рабочих дней
                            </h3>
                        </div>
                        <div className="advantages-of-zoom__container__inner__item">
                            <img src={D} alt="настройка" />
                            <h3 className='h3-two'>
                                Настройка и запуск рекламной компании для каждого созданого нами веб сайта - <span className='bold'>бесплатно</span>
                            </h3>
                        </div>
                        <div className="advantages-of-zoom__container__inner__item">
                            <img src={E} alt="тех поддержка" />
                            <h3 className='h3-two'>
                                Мы предоставляем бесплатную техническую поддержку<br /> <span className='bold'>в течении 1 года</span>
                            </h3>
                        </div>
                        <div className="advantages-of-zoom__container__inner__item">
                            <img src={F} alt="адаптивность" />
                            <h3 className='h3-two'>
                                Адаптивный дизайн - 100% корректное отображение веб сайта на любых устройствах
                            </h3>
                        </div>
                    </div>
                </div>
            </AnimationOnScroll>
        </div>
    )
}

export default AdvantagesOfZoom
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Media from "react-media"
import { AnimationOnScroll } from 'react-animation-on-scroll';

import GooglePlayButton from '../../components/images/GooglePlayButton.png'
import AppStoreButton from '../../components/images/AppStoreButton.png'
import CloseButton from '../../components/images/svgs/CloseButton.svg'
import DotsNav from '../../pages/main/DotsNav'

import allProjects from './allProjects'

const dataA = allProjects

function OurProjects({ hideShowAll = false }) {
    const [open, setOpen] = useState("")
    const [openContent, setOpenContent] = useState(-1)
    const [page, setPage] = useState(0)
    const ref = useRef(null)
    const [data, setData] = useState(dataA)

    const clicked = (e) => {
        let i = parseInt(e.currentTarget.getAttribute('i'))
        setOpen("open-content");
        setOpenContent(i);
    }

    return (
        <div className="our-projects" ref={ref}>
            <AnimationOnScroll animateOnce={true} animateIn="animate__swing" initiallyVisible={true}>
                <h1>Наши проекты</h1>
            </AnimationOnScroll>
            <div className='our-projects-inside'>
                <div className="grid-container">
                    <Media query="(max-width: 1200px)">
                        {matches =>
                            matches ? (<>
                                {data.slice(page * 6, page * 6 + 6).map((instance, i) =>
                                    <div key={i} className={`grid-item element-${i}`} i={page * 6 + i} onClick={(e) => clicked(e)}>
                                        <div className="background">
                                            <div className="inside">
                                                <div className="content">
                                                    <h4>{instance.title}</h4>
                                                    <img className='PreviewProduct' src={instance.image} alt="Preview Product" />
                                                    <div className="images">
                                                        {instance.googleLink &&
                                                            <a href={instance.googleLink}
                                                                target="_blank" rel="noreferrer"
                                                            >
                                                                <img src={GooglePlayButton} alt="Google Play" />
                                                            </a>
                                                        }
                                                        {instance.appleLink &&
                                                            <a href={instance.appleLink}
                                                                target="_blank" rel="noreferrer"
                                                            >
                                                                <img src={AppStoreButton} alt="App Store" />
                                                            </a>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                            ) : (<>
                                {data.slice(page * 12, page * 12 + 12).map((instance, i) =>
                                    <div key={i} className={`grid-item element-${i}`} i={page * 12 + i} onClick={(e) => clicked(e)}>
                                        <div className="background">
                                            <div className="inside">
                                                <div className="content">
                                                    <h4>{instance.title}</h4>
                                                    <img className="PreviewProduct" src={instance.image} alt="Preview Product" />
                                                    <div className="images">
                                                        {instance.googleLink &&
                                                            <a href={instance.googleLink}
                                                                target="_blank" rel="noreferrer"
                                                            >
                                                                <img src={GooglePlayButton} alt="Google Play" />
                                                            </a>
                                                        }
                                                        {instance.appleLink &&
                                                            <a href={instance.appleLink}
                                                                target="_blank" rel="noreferrer"
                                                            >
                                                                <img src={AppStoreButton} alt="App Store" />
                                                            </a>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )} </>
                            )
                        }
                    </Media>
                </div>
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInDown">
                    <div className="bottom">
                        <Media query="(max-width: 1200px)">
                            {matches =>
                                matches ?
                                    <DotsNav quantity={Math.ceil(data.length / 6)} setPage={setPage} myref={ref} />
                                    :
                                    <DotsNav quantity={Math.ceil(data.length / 12)} setPage={setPage} myref={ref} />
                            }
                        </Media>
                        {!hideShowAll &&
                            <h2>
                                <Link to='/our-works'>
                                    Смотреть все
                                </Link>
                            </h2>
                        }
                    </div>
                </AnimationOnScroll>
                {open &&
                    <div className="show">
                        <div className={`overlay ${open}`}>
                            <div className="container">
                                <img className='close-button' src={CloseButton} alt='close' onClick={() => { setOpen(""); setOpenContent(-1) }} />
                                <div className="content">
                                    {/* media -> show {<h1>{data[openContent].title}</h1>} */}
                                    <img className='left-img' src={data[openContent].onShowImage} alt="Product" />
                                    <div className="text">
                                        {/* media -> remove */}<h1>{data[openContent].title}</h1>
                                        <h5>{data[openContent].type}</h5>
                                        {data[openContent].about}
                                        <div className="images">
                                            {data[openContent].googleLink &&
                                                <a href={data[openContent].googleLink}
                                                    target="_blank" rel="noreferrer"
                                                >
                                                    <img src={GooglePlayButton} alt="Google Play" />
                                                </a>
                                            }
                                            {data[openContent].appleLink &&
                                                <a href={data[openContent].appleLink}
                                                    target="_blank" rel="noreferrer"
                                                >
                                                    <img src={AppStoreButton} alt="App Store" />
                                                </a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default OurProjects
import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'

import tick from '../../../../components/images/svgs/tick.svg'

const data = [
    {
        text: <p><span className='bold'>Хороший доход</span><br /><br />

            Программисты разрабатывающие мобильные приложения для операционной системы IOS, могут считаться самыми высокооплачиваемыми в сегменте mobile developer. Средняя заробатная плата ios программиста в Алматы - 350 000 тенге</p>
    },
    {
        text: <p><span className='bold'>Можно работать удаленно</span><br /><br />

            Многие разработчики не привязаны к компаниям и могут себе позволить работать удаленно там, где им удобно. Чтобы создавать приложения, нужны только знания и MacBook, а не офисные стены</p>
    },
    {
        text: <p><span className='bold'>Популярный продукт</span><br /><br />

            Мобильные приложения на iOS приносят самую большую прибыль на рынке. Рынок мобильного Интернета значительно вырос за эти годы, и эксперты прогнозируют дальнейший динамичный рост. Использование мобильного Интернета превысило десктопный в 2014 году, а 80% интернет-пользователей владеют смартфонами</p>
    },
    {
        text: <p><span className='bold'>Дефицит</span><br /><br />

            Рынок СНГ а в особенности Казахстана, действительно исптывает сильнейший дефицит IOS программистов. Сейчас самый подходящий момент для освоения этой профессии, так как рынок еще не насыщен и нуждается в высококвалифицированных кадрах</p>
    },
    {
        text: <p><span className='bold'>Творческий и карьерный рост</span><br /><br />

            Всё зависит только от тебя самого: имея изначально хорошую идею и грамотно воплотив её в жизнь,не вкладывая ресурсов, можно в самые короткие сроки создать международный проект, аналогичный Instagram и получить $$$</p>
    },
    {
        text: <p><span className='bold'>Простой для изучения язык SWIFT</span><br /><br />

            Это надёжный и интуитивно понятный язык программирования от Appleкодом вам достаточно просто интересной идеи, чтобы создать нечто невероятное помощи которого можно создавать приложения для iOS, Mac, Apple TV и Apple Watch. Он предоставляет разработчикам небывалую свободу творчества. Благодаря этому простому и удобному языку с открытым </p>
    },
]

function WhyBecomeIOSDev() {
    return (
        <div className='why-becomve-ios-dev'>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                <h1>IOS-разработчиками становятся, потому что</h1>
            </AnimationOnScroll>
            <div className="why-becomve-ios-dev__container">
                {data.map(({ text }, i) =>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft" key={i}>
                        <div className="why-becomve-ios-dev__container__item">
                            <img src={tick} alt="tick" />
                            {text}
                        </div>
                    </AnimationOnScroll>
                )}
            </div>
        </div>
    )
}

export default WhyBecomeIOSDev
// import image1 from '../../components/images/allProducts/PreviewProduct.png'
// import onShowImage1 from '../../components/images/allProducts/OurProject-onShowImage.png'

import image2 from '../../components/images/allProducts/WaterMeasurement.png'
import image3 from '../../components/images/allProducts/perfeccto.jpg'
import image4 from '../../components/images/allProducts/braunis.jpg'
import image5 from '../../components/images/allProducts/farcount.jpeg'
import image6 from '../../components/images/allProducts/psychology-journal.jpg'
import image7 from '../../components/images/allProducts/aaa-taxi.jpg'
import image8 from '../../components/images/allProducts/imuslim.jpg'
import image9 from '../../components/images/allProducts/teamgis.png'
import image10 from '../../components/images/allProducts/image11png.png'
import image11 from '../../components/images/allProducts/img11.png'
import image12 from '../../components/images/allProducts/img12.jpg'
import image13 from '../../components/images/allProducts/img13.jpg'
import image14 from '../../components/images/allProducts/img14.png'
import image15 from '../../components/images/allProducts/img15.png'
import image16 from '../../components/images/allProducts/img16.png'
import image17 from '../../components/images/allProducts/img17.jpg'
import image18 from '../../components/images/allProducts/img18.jpeg'
import image19 from '../../components/images/allProducts/img19.jpg'
import image20 from '../../components/images/allProducts/img20.jpg'
import image21 from '../../components/images/allProducts/img21.jpg'
import image22 from '../../components/images/allProducts/img22.jpg'
import image23 from '../../components/images/allProducts/img23.jpg'
import image24 from '../../components/images/allProducts/img24.jpg'
import image25 from '../../components/images/allProducts/img25.jpg'
import image26 from '../../components/images/allProducts/img26.jpg'
import image27 from '../../components/images/allProducts/img27.jpg'
import image28 from '../../components/images/allProducts/img28.jpg'
import image29 from '../../components/images/allProducts/img29.jpg'
import image30 from '../../components/images/allProducts/img30.jpg'
import image31 from '../../components/images/allProducts/img31.jpg'
import image32 from '../../components/images/allProducts/img32.jpg'
import image33 from '../../components/images/allProducts/img33.jpg'

const allProjects = [
    // {
    //     title: 'SMALL',
    //     type: 'Мобильное приложение',
    //     about: <span className='h6'>Крупнейший в Казахстане каталог модной мужской и женской обуви, аксессуаров.<br />
    //         Совершать покупки можно в два клика. Это просто, удобно и надёжно: в каталоге предлагаются только фирменные товары известных брендов – как массовых, так и премиум-класса.<br /><br />

    //         Здесь можно подобрать базовый мужской или женский гардероб на каждый день и украсить его эффектными дизайнерскими аксессуарами.
    //     </span>,
    //     image: image1,
    //     onShowImage: onShowImage1,
    //     googleLink: '',
    //     appleLink: ''
    // },
    {
        title: 'Water Measurement',
        type: 'Мобильное приложение',
        about: <span className='h6'>Разработка мобильного приложения WaterMeasurement под ключ IOS & Android.<br /><br />

            Представляем вашему вниманию бесплатное мобильное приложения WaterMeasurement для Android и IOS устройств!<br /><br />

            Данное приложение разработано для определения пригодности питьевой воды.
        </span>,
        image: image2,
        onShowImage: image2,
        googleLink: 'https://play.google.com/store/apps/details?id=com.waterAlcor&hl=ru',
        appleLink: 'https://apps.apple.com/be/app/watermeasurement/id1533060188'
    },
    {
        title: 'Perfectto',
        type: 'Мобильное приложение',
        about: <span className='h6'>Perfectto – крупнейший в Казахстане каталог модной мужской и женской обуви, аксессуаров.<br /><br />

            Совершать покупки можно в два клика. Это просто, удобно и надёжно: в каталоге предлагаются только фирменные товары известных брендов – как массовых, так и премиум-класса.<br /><br />

            Здесь можно подобрать базовый мужской или женский гардероб на каждый день и украсить его эффектными дизайнерскими аксессуарами.
        </span>,
        image: image3,
        onShowImage: image3,
        googleLink: 'https://play.google.com/store/apps/details?id=com.perfecto&hl=ru&gl=US',
        appleLink: 'https://apps.apple.com/us/app/perfectto/id1544425763'
    },
    {
        title: 'Браунис',
        type: 'Мобильное приложение',
        about: <span className='h6'>В ассортименте напитки, горячие и холодные, а также фаст-фуд и горячие блюда
        </span>,
        image: image4,
        onShowImage: image4,
        googleLink: '',
        appleLink: 'https://apps.apple.com/us/app/%D0%B1%D1%80%D0%B0%D1%83%D0%BD%D0%B8%D1%81/id1557849917'
    },
    {
        title: 'farcount',
        type: 'Мобильное приложение',
        about: <span className='h6'>farcount - приложения для учета и печати накладных
        </span>,
        image: image5,
        onShowImage: image5,
        googleLink: 'https://play.google.com/store/apps/details?id=alux.farcount',
        appleLink: 'https://apps.apple.com/kz/app/far-count/id1546715324'
    },
    {
        title: 'Psychology journal',
        type: 'Мобильное приложение',
        about: <span className='h6'>Psychology journal - Журналы, книги, статьи про психологию, отношения, саморазвитие у Вас в кармане
        </span>,
        image: image6,
        onShowImage: image6,
        googleLink: '',
        appleLink: 'https://apps.apple.com/us/developer/ivan-vostrikov/id1046830352#see-all/i-phone-apps'
    },
    {
        title: 'ААА TAXI',
        type: 'Мобильное приложение',
        about: <span className='h6'>Быстрая регистрация в Яндекс.Про (Яндекс.Такси)<br />
            Моментальные круглосуточные выплаты водителям такси на любые банковские карты.
        </span>,
        image: image7,
        onShowImage: image7,
        googleLink: 'https://play.google.com/store/apps/details?id=com.aaa.drive',
        appleLink: ''
    },
    {
        title: 'iMuslim',
        type: 'Мобильное приложение',
        about: <span className='h6'>Приложение iMuslim показывает точное время молитвы, анимированные компас и карта Киблы, 99 имен Аллаха с полным описанием, священный Коран (аудиозаписи), уроки обучения чтению Корана (Тадживид), обучение Намазу, крепость мусульманина, дуа с фонетическим написанием и переводами, статьи по категориям, электронный Тасбих для учета зикра, вопрос к Имаму, Зекет, местонахождение мечетей, намазханы, халяль организаций и др. с возможностью сохранять в закладках, без рекламы.
        </span>,
        image: image8,
        onShowImage: image8,
        googleLink: 'https://play.google.com/store/apps/details?id=com.zoom.imuslim&hl=en_US&gl=US',
        appleLink: 'https://apps.apple.com/kz/app/imuslim/id1572608779'
    },
    {
        title: 'TeamGis',
        type: 'Мобильное приложение',
        about: <span className='h6'>Приложение TeamGis это удобный и эффективный инструмент онлайн бронирования столиков и возможность ознакомления с заведениями в сфере общественного питания
        </span>,
        image: image9,
        onShowImage: image9,
        googleLink: 'https://play.google.com/store/apps/details?id=com.zoom.teamgis',
        appleLink: 'https://apps.apple.com/kz/app/teamgis/id1563372382'
    },
    {
        title: 'Алатау кус',
        type: 'Мобильное приложение',
        about: <span className='h6'>Онлайн-магазин "Алатау - Кус" - в ассортименте курочка и специи.
        </span>,
        image: image10,
        onShowImage: image10,
        googleLink: 'https://play.google.com/store/apps/details?id=com.project.alatau_kus',
        appleLink: ''
    },
    {
        title: 'MeeToo',
        type: 'Мобильное приложение',
        about: <span className='h6'>Мобильное приложение MeeToo создано для всех, кто может столкнуться с проблемой семейно – бытового насилия. Данное приложение поможет найти выход из критической ситуации, а порой даже спасти жизнь. Приложение MeeToo содержит полную информацию о кризисных центрах, и, главное, кнопку SOS, благодаря которой можно сразу сообщить в правоохранительные органы о вероятной угрозе, также вы сможете скачать образцы заявлений, получить бесплатную юридическую и психологическую помощь, прочитать реальные истории женщин, которые остановили в своей жизни насилие, также найти информацию о проводимых тренингах/семинарах для женщин и девочек.<br /><br />

            <strong>Мобильное приложение разработано в рамках проекта «Социальные инновации в Центральной Азии» при финансовой поддержке Программы USAID по поддержке гражданского общества в Центральной Азии, реализуемой Фондом Евразия.</strong>
        </span>,
        image: image11,
        onShowImage: image11,
        googleLink: 'https://play.google.com/store/apps/details?id=com.salamaleikim.zan',
        appleLink: 'https://apps.apple.com/us/app/perfectto/id1544425763'
    },
    {
        title: 'iPrize: Конструктор конкурсов',
        type: 'Мобильное приложение',
        about: <span className='h6'>iPrize - это площадка, где всего лишь за 5 минут вы можете создать Свой Собственный Конкурс, в котором Победителя выберут миллионы пользователей. Участвуя в конкурсах других пользователей, Вы сами сможете заработать славу, признание и деньги.<br /><br />

            iPrize - идеальное открытие именно для вас, ведь это платформа нового формата, созданная для креативных и талантливых людей! iPrize предоставляет возможность продемонстрировать свои способности и таланты миру, а также заработать славу, признание и деньги.<br /><br />
        </span>,
        image: image12,
        onShowImage: image12,
        googleLink: 'https://play.google.com/store/apps/details?id=com.jsbproject&hl=ru',
        appleLink: 'https://apps.apple.com/kz/app/iprize-%D0%BA%D0%BE%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%82%D0%BE%D1%80-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%BE%D0%B2/id1490848004'
    },
    {
        title: 'DKNEWS',
        type: 'Мобильное приложение',
        about: <span className='h6'>DKNews.kz - круглосуточный мультимедийный информационно-аналитический портал. Размещает ленту новостей различной тематики: экономика, политика, финансы, общество, культура, спорт, актуальные события в Республике Казахстан и мире, а также аналитические материалы газеты «Деловой Казахстан». На портале размещаются также свежие номера «Делового Казахстана» в формате PDF и архив газеты.<br /><br />

            www.dknews.kz
        </span>,
        image: image13,
        onShowImage: image13,
        googleLink: 'https://play.google.com/store/apps/details?id=com.dknews',
        appleLink: 'https://apps.apple.com/kz/app/dknews-kz/id1501129337'
    },
    {
        title: 'Городские грузо- перевозки',
        type: 'Мобильное приложение',
        about: <span className='h6'>Цель проекта «Городские грузоперевозки»--- обеспечить своим пользователям наилучшие условия для быстрого и выгодного осуществления перевозки любого груза по городу и области, в удобное вам время.
            Приложением пользоваться просто - размещаете объявление с указанием адреса погрузки
            и выгрузки, с кратким описанием груза. После чего ожидаете звонка от заинтересовавшегося водителя и на прямую обсуждаете условия перемещения вашего груза. Оплачиваете водителю по завершении перевозки сумму, оговоренную при обсуждении доставки вашего груза.
        </span>,
        image: image14,
        onShowImage: image14,
        googleLink: 'https://play.google.com/store/apps/details?id=com.freightapp',
        appleLink: 'https://apps.apple.com/tj/app/%D0%B3%D0%BE%D1%80%D0%BE%D0%B4%D1%81%D0%BA%D0%B8%D0%B5-%D0%B3%D1%80%D1%83%D0%B7%D0%BE%D0%BF%D0%B5%D1%80%D0%B5%D0%B2%D0%BE%D0%B7%D0%BA%D0%B8/id1535763331'
    },
    {
        title: 'Мечтай и стройней',
        type: 'Мобильное приложение',
        about: <span className='h6'>Приложение «Мечтай и стройней» — это пошаговое руководство по использованию силы мысли для достижения стройного тела.
            Выполняйте комплекс мысленных упражнений «Мечтай и стройней», всего 4 минуты в день в тишине и покое, и вы достигнете желаемого веса легко и надолго.
            В чем уникальность данного метода похудения:
            Методика «Мечтай и стройней», основана на знаниях о влиянии мысли на тело. Этим знаниям больше двух тысяч лет. Их суть – «Вы получаете то, на чем настойчиво фокусируете свое внимание». Если вы регулярно будете правильно думать о своем стройном теле, вы получите его.
        </span>,
        image: image15,
        onShowImage: image15,
        googleLink: 'https://play.google.com/store/apps/details?id=com.dreamandslim&hl=en_US',
        appleLink: 'https://apps.apple.com/ee/app/%D0%BC%D0%B5%D1%87%D1%82%D0%B0%D0%B9-%D0%B8-%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BD%D0%B5%D0%B9/id1525666777'
    },
    {
        title: 'Комек Ломбард',
        type: 'Мобильное приложение',
        about: <span className='h6'>Разработка мобильного приложения для Android и IOS под ключ.<br /><br />

            Комек Ломбард - мобильное приложение с помощью которого Вы сможете:<br />
            - Получить актуальную информацию обо всех ваших залоговых билетах;<br />
            - Продлить или частично уменьшить сумму задолженности по займу;<br />
            - Просмотреть историю уже выкупленных вами залоговых билетов;<br />
            - Найти адрес ближайшего к вам отделения для оформления займа;<br />
            - Получить ответ на интересующий вас вопрос или связаться с менеджером.
        </span>,
        image: image16,
        onShowImage: image16,
        googleLink: 'https://play.google.com/store/apps/details?id=com.komeklombard&hl=ru',
        appleLink: 'https://apps.apple.com/kz/app/%D0%BA%D0%BE%D0%BC%D0%B5%D0%BA-%D0%BB%D0%BE%D0%BC%D0%B1%D0%B0%D1%80%D0%B4/id1543329937'
    },
    {
        title: 'UNIVERSAL LOGISTICS (KZ)',
        type: 'Мобильное приложение',
        about: <span className='h6'>
            Функционал приложения :<br />
            -Онлайн подача заявки<br />
            -Онлайн создание ТТН по индивидуальному изначально заданному шаблону<br />
            -Персональная адресная книга для клиента. Возможность забить один раз пункт назначения, определенного получателя, дать какие либо комментарии, и в дальнейшем при повторном создание заявки или ТТН необходимо будет только выбрать название получателя. Все данные будут автоматически подгружаться<br />
            -Вложение файла (схемы проезда, инвойсы, доверенности и тд.)<br />
            -Интеграция с картой (гугл мапс или 2GIS)<br />
            -Слежение по номеру накладной<br />
            -Архив всех поданных заявок, текущих и доставленных ТТН<br />
            -Поиск по личному кабинету (поиск ТТН, заявок, поиск по слову в ТТН или Заявке)<br />
            <a href="www.ultrans.kz">
                www.ultrans.kz
            </a>
        </span>,
        image: image17,
        onShowImage: image17,
        googleLink: 'https://play.google.com/store/apps/details?id=kz.ultrans.logistics',
        appleLink: 'https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1046830353&mt=8'
    },
    {
        title: 'Астана СУ',
        type: 'Мобильное приложение',
        about: <span className='h6'>Ниже, описаны все дополнительные работы по мобильному приложению, выполненые нашей командой:<br />

            1. Реализована возможность добавления более одного лицевого счета в аккаунт.<br />
            2. Реализовано отображения новостей с основного сайта.<br />
            3. Добавлена возможность отправлять заявки на опломбировку.<br />
            4. Проведена интеграция с системой "Вавиот" (удаленный снятия показании счетчиков).<br />
            5. Реализовано отображения графиков и таблиц с системы "Вавиот" .<br />
            6. Переработан функционал передачи показании счетчиков воды.<br />
            7. Оптимизирована работа приложения с учетом новой версии операционных систем.<br />
            8. Реализован перезапуск приложения при смене языков.
        </span>,
        image: image18,
        onShowImage: image18,
        googleLink: 'https://play.google.com/store/apps/details?id=com.astanasuapp',
        appleLink: 'https://apps.apple.com/us/app/astanasu/id1474102120'
    },
    {
        title: 'Проверка штрафов ГИБДД для PartApp',
        type: 'Мобильное приложение',
        about: <span className='h6'>Разработка дополнительного функционала для мобильного приложения "PART APP" под IOS и Android. <br /><br />

            Проверить штрафы граждани Казахстана теперь могут он лайн, через приложение которое разработала наша команда. Не нужно больше ехать в ГИБДД, стоять в очереди. Для проверки нужно всего лишь ввести свой ИНН или номер авто и техпаспорта. Приложение также отправляет пуш-уведомления о новых штрафах. Пользоваться мобильным приложением могут жители всего Казахстана, Алматы, Астана, Караганда, Шымкент, Павлодар, Усть-Каменогорск и другие городах республики.<br /><br />

            В данное время приложение проходит верификацию для размещения на Google Play и App Store.
        </span>,
        image: image19,
        onShowImage: image19,
        googleLink: '',
        appleLink: ''
    },
    {
        title: 'NAUQAN KZ',
        type: 'Мобильное приложение',
        about: <span className='h6'>Разработка мобильного приложения (Androi+IOS ),веб сайта и логотипа под ключ.<br />

            Приложение NAUQAN - это Ваш гид в мире скидок. Это тот самый случай, когда в одном приложении собраны лучшие акционные предложения со всего Казахстана.<br />

            NAUQAN станет Вашим другом и советчиком в широком спектре товаров и услуг, ведь именно благодаря ему Вы всегда будете в курсе всех скидок и акций, которые проходят в Вашем городе.<br />

            Мы всегда рады новым предложениям как со стороны пользователей, так и со стороны бизнес-партнеров.
            сайт: <a href="www.nauqan.kz">www.nauqan.kz</a>
        </span>,
        image: image20,
        onShowImage: image20,
        googleLink: '',
        appleLink: 'https://apps.apple.com/us/app/nauqan/id1464711943'
    },
    {
        title: 'VeloFriends KZ',
        type: 'Мобильное приложение',
        about: <span className='h6'>Разработка веб сервера и мобильного приложения для Android и IOS под ключ.<br />
            VeloFriends Казахстан - это общественная инициатива, которая является также диалоговой платформой и приглашает объединить усилия и инициативы для более эффективного достижения глобальных целей по развитию велодвижения в стране.<br />
            <strong>Приложение позволяет:</strong><br />
            - Записывать свой маршрут: скорость,растояние,время прохождение и делиться им с пользователями приложения.<br />
            - Быть в курсе всех последних вело-событий.<br />
            - Выбрать и пройти по координатам GPS наиболее популярные маршруты в приложении<br />
            - Делиться комментариями о разных маршрутах<br />
            <strong>Цели:</strong><br />
            - Участие государства (ГЧП) <br />
            - Популяризация здорового образа жизни <br />
            - Объединение усилий существующих велоинициатив <br />
            - Развитие эко-туризма в Казахстане.
        </span>,
        image: image21,
        onShowImage: image21,
        googleLink: 'https://play.google.com/store/apps/details?id=com.evput.velo&hl=ru',
        appleLink: 'https://itunes.apple.com/kz/app/velo-friends/id1218752983?mt=8'
    },
    {
        title: 'СуШЕФ',
        type: 'Мобильное приложение',
        about: <span className='h6'>Мобильное приложение СуШЕФ - доставка продуктов с рецептами для собственного приготовления блюд.<br />

            Вы хотите сделать ваши ужины по-настоящему особенными? Что если продукты приедут сами к Вам домой? И не просто продукты, а расфасованные по ингредиентам и с пошаговыми фото-рецептами.Мобильное приложение CуШЕФ - это то, что Вам нужно! Не нужно ломать голову над тем, что приготовить сегодня, позвольте себе не тратить время и нервы на пробки и очереди.
        </span>,
        image: image22,
        onShowImage: image22,
        googleLink: 'https://play.google.com/store/apps/details?id=com.newsousheft',
        appleLink: 'https://apps.apple.com/kz/app/souschef/id1515884591'
    },
    {
        title: 'ULEX (UNIVERSAL LOGISTICS EXCHANGE)',
        type: 'Мобильное приложение',
        about: <span className='h6'>Создание сайта и мобильного приложения под ключ под IOS и Android для Казахстанской компании "Universal Logistics"<br /><br />

            Приложение предназначено для экспедиторов подвижных составов и для грузоотправителей любой сферы деятельности. Система он-лайн биржи экспедиторов и грузоотправителей. Мобильное приложение является этапом реализации комплекса системы бирж грузовых транспортных средств и грузов.<br />
            Приложение работает по тендерной системе схожей с UBER.<br />
            <a href="www.ulex.kz">www.ulex.kz</a>
        </span>,
        image: image23,
        onShowImage: image23,
        googleLink: 'https://play.google.com/store/apps/details?id=com.universal_logistics.ulex&hl=ru',
        appleLink: 'https://itunes.apple.com/us/app/ulex/id1200747698?mt=8'
    },
    {
        title: 'TUMAR',
        type: 'Мобильное приложение',
        about: <span className='h6'>Создание мобильного приложения под ключ для Android.
            Соревнуйтесь в знаниях со своими друзьями либо сделайте вызов случайному пользователю.
            Получайте баллы за правильные ответы и увеличивайте свой уровень.
            Это самая первая и простая версия приложения, для облегчения жизни будущих и начинающих водителей.
            С каждой новой версией функционал должен добавляться.<br /><br />

            Функционал:<br />
            -Теория<br />
            -Самопроверка<br />
            -Online Мультиплеер <br />
            -Чат<br />
            -Push уведомления<br />
            -Рейтинг
        </span>,
        image: image24,
        onShowImage: image24,
        googleLink: 'https://play.google.com/store/apps/details?id=com.zoomapps.tumar',
        appleLink: ''
    },
    {
        title: 'БЛИЗКИЕ ВСЕГДА РЯДОМ',
        type: 'Мобильное приложение',
        about: <span className='h6'>Разработка мобильного приложения по поиску ребенка "Близкие Всегда Рядом" для IOS и Android.<br /><br />

            Данное приложение может бесплатно скачать (в «Play market» или «App Store»), любой человек, у которого есть сотовый телефон и выход в интернет. <br />
            Приложение позволяет отслеживать местонахождение пользователя на картах.<br /><br />

            Для корректной работы приложения необходимо его скачать на оба телефона, произвести регистрацию как родитель и дитя, авторизоваться в приложении и после ввести код в приложении установленное на втором смартфоне (За которым планируется ввести отслеживание геопозиции). <br /><br />

            Есть возможность: отследить траекторию вашего ребенка за день,посмотреть историю мест за предыдущий месяц, а ребенок может нажать кнопку тревоги в случаи экстренной ситуации. <br /><br />

            Приложение находится на стадии модерации в AppStore, пока доступно только в Play Market.
        </span>,
        image: image25,
        onShowImage: image25,
        googleLink: 'https://play.google.com/store/apps/details?id=com.zoomapps.fiat',
        appleLink: ''
    },
    {
        title: 'HealthBook KZ',
        type: 'Мобильное приложение',
        about: <span className='h6'>Создание мобильного приложения под ключ под IOS и ANDROID для инновационного портала о здоровье HealthBook.kz<br />
            Функционал мобильного приложения:<br /><br />

            Аккаунт Здоровья :<br />
            -Ведите свой Дневник Здоровья<br />
            -Заносите любые показатели<br />
            -Сохраняйте свои мед. данные -(МРТ, Рентген снимки, анализы и многое другое)<br />
            -​Врачи<br /><br />

            Найдите своего специалиста:<br />
            -Оставляйте отзывы и оценивайте его работу<br />
            -Записывайтесь на прием к врачу без очередей и томительного ожидания<br />
            -Задавайте свои вопросы напрямую специалисту<br />
            -Семья<br /><br />

            Следите за здоровьем близких:<br />
            -Создавайте Дневники здоровья<br />
            -Заносите важную информацию - (Болезни, Посещение врача, принимаемые лекарства и т.д.)<br />
            -Защита<br /><br />

            В данный момент приложение находится на стадии завершения и публикации в App Store и Google Play.
        </span>,
        image: image26,
        onShowImage: image26,
        googleLink: '',
        appleLink: ''
    },
    {
        title: 'ФотоМото KZ',
        type: 'Мобильное приложение',
        about: <span className='h6'>Разрабокта мобильного приложения "ФотоМото" Для Android и IOS<br /><br />

            Заказчик о приложении: <br />
            Мы молодая Казахстанская семья у которой появился чудный малыш! Как и все родители мы хотели запечатлеть каждую секунду жизни нашего сына. Но памяти на телефоне всегда не достаточно для всех ярких моментов. И к тому же однажды потеряв телефон мы решили что впредь будем распечатывать фотографии, как в старые, добрые времена! На бумаге!<br /><br />

            Но ходить по фотосалонам у нас не хватало времени, поэтому мы создали ФОТОМОТО!<br /><br />

            ФОТОМОТО – это просто, удобно, быстро и качественно!<br /><br />

            Мы верим в то, что самые яркие моменты в жизни должны быть осязаемы и мы гордимся тем, что помогаем людям улыбаться чаще!<br /><br />

            Загрузите свои цифровые фотографии в приложении,оформите заказ,получить фотографии на качественной фотобумаге с доставкой на дом.
        </span>,
        image: image27,
        onShowImage: image27,
        googleLink: 'https://play.google.com/store/apps/details?id=com.evput.fotomoto',
        appleLink: 'https://itunes.apple.com/us/app/%D1%84%D0%BE%D1%82%D0%BE%D0%BC%D0%BE%D1%82%D0%BE/id1287201665?l=ru&ls=1&mt=8'
    },
    {
        title: 'Home Credit Bank KZ',
        type: 'Мобильное приложение',
        about: <span className='h6'>Разработка приложения для социальной сети FACEBOOK и сайта для Home Credit Bank KZ<br /><br />

            Приложение позволяет:<br />
            -Промониторить практически все депозиты банков в Республике Казахстан (Информация постоянно обновляется парсероом)<br />
            -Оставить заявку на депозит <br />
            -Отфильтровать депозиты по разным параметрам<br />
            -Сравнить выбранные депозиты<br />
            -Получать последние  актуальные новости<br /><br />

            Ссылки на кнопки расположены ниже, для перехода в приложение или на сайт:<br />
            <a href="www.bestdepozit.kz">www.bestdepozit.kz</a>
        </span>,
        image: image28,
        onShowImage: image28,
        googleLink: '',
        appleLink: ''
    },
    {
        title: 'JANA TV',
        type: 'Мобильное приложение',
        about: <span className='h6'>Создание Мобильного приложения "JANA TV"  для Android и IOS.<br /><br />

            JANA – это круглосуточный доступ к самым лучшим и свежим клипам исполнителей Центральной Азии. JANA – это ваша возможность погрузится в мир музыкального видео Казахстана, Киргизии, Узбекистана.<br /><br />

            JANA – только у нас еженедельный хит-парад основанный на вашем предпочтении, ежедневно обновляемый раздел «Новинки», а так же клипы всех музыкальных направлений: поп, рок, этно, клубная, альтернативная, национальная и многое другое.<br /><br />

            Создай свой уникальный плейлист под настроение. JANA – это новый формат онлайн телевидения, теперь только вы решаете что вам смотреть и слушать.<br /><br />

            Все видео приложения абсолютно бесплатно и в HD качестве.<br />
            Добавлена функция он лай вещания видео.<br />
            JANA – это твой проводник в мир еще неизвестной, но качественной музыки.
        </span>,
        image: image29,
        onShowImage: image29,
        googleLink: 'https://play.google.com/store/apps/details?id=kz.janatv.app&hl=ru',
        appleLink: 'https://itunes.apple.com/ru/app/jana/id1098227039?mt=8'
    },
    {
        title: 'АЗС KZ',
        type: 'Мобильное приложение',
        about: <span className='h6'>Разработка мобильного приложения и сайта АЗС.KZ под ключ IOS & Android.<br /><br />

            Получайте в любой момент всю самую актуальную и объективную информацию о большинстве АЗС Казахстана. Установив приложение, вы всегда будете знать, где лучше заправиться, а значит - экономить свое время и деньги! <br /><br />

            ВОЗМОЖНОСТИ ПРИЛОЖЕНИЯ:<br />
            Мобильное приложение позволяет не только найти ближайшую заправку, но и сравнить цены, сервис точек обслуживания, оценить рейтинг АЗС, который формируется самими пользователями. Если вы отправляетесь в длительную поездку, то сможете сформировать оптимальный список заправочных станций на своём пути следования.<br />
            Сервис не спонсируется ни одной сетью заправок, поэтому в приложении доступна только объективная информация об АЗС Казахстана: местоположение, новости, акции и т.д. Данные обновляются постоянно, мы регулярно добавляем новые точки.
            В приложении АЗС.KZ, водитель всегда может найти лучшую цену на топливо путешествуя по Казахстану.
        </span>,
        image: image30,
        onShowImage: image30,
        googleLink: 'https://play.google.com/store/apps/details?id=kz.xn__80ao4a.android',
        appleLink: 'https://itunes.apple.com/kz/app/azs.kz/id1080380411?mt=8'
    },
    {
        title: 'МИА «КАЗИНФОРМ»',
        type: 'Мобильное приложение',
        about: <span className='h6'>Разработка официального мобильного приложения под IOS и Android для МИА «КАЗИНФОРМ» под ключ.
            "Международное Информационное Агентство «КАЗИНФОРМ» - ведущее государственное информационное агентство, первое в Казахстане получившее статус международного уровня.<br />  <br />

            Медиа-агентство на протяжении более 90 лет неизменно сохраняет авторитет надежного и оперативного источника информации о политике, экономике, образовании, здравоохранении, спорте, культуре и других ключевых составляющих жизнедеятельности общества.<br />

            Приложение позволяет получать самые последние новости используя ваше мобильное устройство.<br />

            4 Языка: Казахский,русский,английский,латинский<br />
            - Добавляйте новости в избранное<br />
            - Функционал для слабовидящих<br />
            - Делитесь новостями с друзьями<br />
            - Пуш уведомления
        </span>,
        image: image31,
        onShowImage: image31,
        googleLink: 'https://play.google.com/store/apps/details?id=kz.inform.informkz',
        appleLink: 'https://itunes.apple.com/us/app/kazinform/id1301991325?l=ru&ls=1&mt=8'
    },
    {
        title: 'Интернет Магазин MARTI KZ',
        type: 'Мобильное приложение',
        about: <span className='h6'>Разработка мобильного приложения для интернет магазина www.marti.kz под ключ для ОС Android.<br /><br />

            Marti.Kz - интернет-магазин по продаже мобильных телефонов, смартфонов, планшетов и другой техники, а также аксессуаров к ней в Казахстане. Мобильное Приложение помогает удобно произвести он лайн покупку нужной Вам техники в интернет магазине и дублирует все функции сайта.
        </span>,
        image: image32,
        onShowImage: image32,
        googleLink: 'https://play.google.com/store/apps/details?id=shop.marti.kz',
        appleLink: ''
    },
    {
        title: 'UNION TAXI',
        type: 'Мобильное приложение',
        about: <span className='h6'>Разработка мобильного приложения под ключ для IOS и Android.<br />
            В этот раз мы разработали APP для заказчика из Казахстана, но география распространения приложения намного шире и охватывает практически 50% пользователей земного шара.<br />
            Приложение позволяет указав откуда и куда Вы поедете выбрать самое дешевое и удобное такси.<br />
            Приложение мультиязычно, скачав его вы получаете интерфейс на том же языке, на котором работает Ваш телефон.<br /><br />

            Чтобы сэкономить и заказать такси дешевле или вызвать такси в аэропорт, используйте одно приложение "Union Taxi" и выбирайте наиболее подходящее Вам такси по стоимость поездки и другим критериям.<br /><br />

            Встроенные карты (Яндекс) помогут выбрать точку подачи и назначения.<br />
            На данный момент подключены: Uber , Yandex Taxi и Careem - для Арабских стран.<br />
            Так же можно выбрать из каталога компаний такси в любом городе Мира используя разные критерии отбора.<br />
            Специалисты нашей компании  стараются создавать максимально легкие в использовании и в то же время функциональные мобильные приложения.
        </span>,
        image: image33,
        onShowImage: image33,
        googleLink: 'https://play.google.com/store/apps/details?id=com.union.zoomapps.myapplication',
        appleLink: ''
    },
]

export default allProjects
import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'

function StepsOfDevelopment() {
    return (
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeftBig">
            <div className='steps-of-development'>
                <h1>Этапы разработки</h1>
                <h2>мобильных приложений в Алматы, Астане и других городах Казахстана.</h2>
                <p>Разработка мобильных приложений — процесс непростой и очень трудоемкий, представляющий из себя полноценную разработку программного обеспечения. Чтобы вам проще было понять, как компания ZOOM создает мобильные приложения, мы коротко расскажем про каждый этап нашей работы.</p>
            </div>
        </AnimationOnScroll>
    )
}

export default StepsOfDevelopment
import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img from '../../../../components/images/study-page/stars-of-profession/A.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';

var dataitems = {
    Datadescription: [
        {
            img,
            text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        {
            img,
            text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        {
            img,
            text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        {
            img,
            text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        {
            img,
            text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        }
    ]
};

function WhyClientsChooseZoom() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 3,
        responsive: [
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 520,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    };

    return (
        <div className='why-clients-choose-zoom'>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                <h1>Почему клиенты выбирают компанию ZOOM?</h1>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__shakeY">
                <Slider {...settings} className='why-clients-choose-zoom__container'>
                    {dataitems.Datadescription &&
                        dataitems.Datadescription.map((maincontent, index) => (
                            <div className="why-clients-choose-zoom__container__item" key={index}>
                                <img src={maincontent.img} alt="client" />
                                <h3 className='h3-two'>{maincontent.text}</h3>
                            </div>
                        ))}
                </Slider>
            </AnimationOnScroll>
        </div>
    )
}

export default WhyClientsChooseZoom
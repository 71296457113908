import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import './main.scss'
import picture from '../../components/images/картинка.png'
import logo from '../../components/images/svgs/logo.svg'
import iconBtn from '../../components/images/svgs/icon-btn.svg'
import plus from '../../components/images/svgs/plus-sign.svg'
import arrow from '../../components/images/svgs/Arrow.svg'

import key from '../../components/images/svgs/why-us/Key.svg'
import chart from '../../components/images/svgs/why-us/Chart.svg'
import lightning from '../../components/images/svgs/why-us/Lightning.svg'
import documentAdd from '../../components/images/svgs/why-us/Document add.svg'
import category from '../../components/images/svgs/why-us/Category.svg'
import check from '../../components/images/svgs/why-us/Check.svg'
import wallet from '../../components/images/svgs/why-us/Wallet.svg'

import OurProjects from '../../hooks/ourProjects/OurProjects'
// import DotsNav from './DotsNav'
import Form from './Form'
import TranspContainer from '../../hooks/transpContainer/TranspContainer'

import { AnimationOnScroll } from 'react-animation-on-scroll';
import LeaveRequest from '../../hooks/leaveRequest/LeaveRequest'

function Main() {
    const [open, setOpen] = useState("")

    return (
        <>
            <Helmet>
                <title>Разработка мобильного приложения, заказать услугу в веб студии ZOOM TheAppLab</title>
                <meta
                    name="description"
                    content="Создание мобильных приложений любого уровня сложности под ключ в Алматы, Астане, других городах Казахстана и СНГ. Автоматизация бизнес-процессов, улучшение сервиса. Лучшее соотношение цены к качеству."
                />
                <meta
                    name="keywords"
                    content="Разработка мобильного приложения, IT-решения, Digital компания, мобильные приложения, компания, Алматы, Астана, создание мобильного приложения, цена - качество"
                />
            </Helmet>
            <main className='main-page'>
                <div className='first-container'>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                        <div className="left">
                            <div className="logo">
                                <img src={logo} alt="logo" />
                            </div>
                            <div className="content">
                                <header className="header">
                                    <h1>Разработаем мобильное приложение</h1>
                                </header>
                                <div className="text">
                                    <h2>Которое будет отлично работать, поможет зарабатывать, «цеплять» пользователей, увеличивать лояльность клиентов.</h2>
                                </div>
                                <div className="buttons">
                                    <button className='btn-white' onClick={() => setOpen("open-content")}>
                                        <span>Оставить заявку</span>
                                    </button>
                                    <a href="https://www.youtube.com/watch?time_continue=2&v=8z6cnGYN71w" target='_blank' rel='noreferrer'>
                                        <button className='btn-trsp'>
                                            <img src={iconBtn} alt="icon" />
                                            <span>СМИ о нас</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRightBig" initiallyVisible={true}>
                        <div className="right">
                            <img src={picture} alt="iphone, macbook, small" />
                            {/* <DotsNav quantity={3} /> */}
                        </div>
                    </AnimationOnScroll>
                </div>
                <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                    <div className="about-us">
                        <div className="circle">
                            <div className="rainbow-background">
                                <div className="text">
                                    <span>Нам <span className='big-15'>15</span> лет</span>
                                </div>
                            </div>
                        </div>
                        <article className="frame-black">
                            <h1>О компании</h1>
                            <p><span className='bold'>ZOOM</span> - профессиональный разработчик мобильных приложений в Алматы, Астане, других городах Казахстана и СНГ. ZOOM - Одна из группы Digital компаний, являющяяся лидером в области разработки мобильных приложений и игр на рынке Казахстана, существующая с 2007 года.<br /><br />

                                Мы разрабатываем мобильные приложения любого уровня сложности под ключ, предлагаем IT-решения, автоматизируем бизнес - процессы, повышаем лояльность ваших заказчиков, улучшаем сервис и работаем на только на положительный результат. Создаем мобильные решения, которыми ежедневно пользуются десятки миллионов людей.

                            </p>
                        </article>
                        <div className="expand-more-about-us">
                            <img src={plus} alt="plus-sign" />
                            <h2>Подробнее о нас</h2>
                        </div>
                    </div>
                </AnimationOnScroll>
                <div className="why-us">
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeftBig">
                        <img className="arrow" src={arrow} alt="arrow" />
                    </AnimationOnScroll>
                    <div className="container">
                        <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                            <h1>Почему мы?</h1>
                        </AnimationOnScroll>
                        <div className="list">
                            <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                                <div className="item">
                                    <img src={key} alt="key" />
                                    <h3>Полный цикл услуг по разработке мобильного приложения:<br />
                                        документация, проектирование, дизайн, разработка, тестирование и менеджмент.</h3>
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                                <div className="item">
                                    <img src={chart} alt="chart" />
                                    <h3>Анализ и решения задач. Вы получатете решения, а не вопросы.</h3>
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                                <div className="item">
                                    <img src={lightning} alt="lightning" />
                                    <h3>Опыт реализации сложных проетов.</h3>
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                                <div className="item">
                                    <img src={documentAdd} alt="documentAdd" />
                                    <h3>Гарантия выполнения обязательств с помощью договора.</h3>
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                                <div className="item">
                                    <img src={category} alt="category" />
                                    <h3>Публикация приложения в AppStore, Google Play Market, Microsoft.</h3>
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                                <div className="item">
                                    <img src={check} alt="check" />
                                    <h3>Мы берём ответственность не только за разработку,<br />
                                        но и за продвижение мобильного приложения.</h3>
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn">
                                <div className="item">
                                    <img src={wallet} alt="wallet" />
                                    <h3>Достуаные цены!
                                        Стоимость нашей работы не вызывает шок.</h3>
                                </div>
                            </AnimationOnScroll >
                        </div >
                    </div >
                </div >
                <OurProjects />
                <TranspContainer>
                    <span className='h1-two'>Оставьте заявку и наши менеджеры свяжутся с Вами в ближайшее время</span>
                    <Form />
                </TranspContainer>
            </main >
            {open &&
                <LeaveRequest open={open} setOpen={setOpen} />
            }
        </>
    )
}

export default Main
